.image-slider{
    z-index: 2;
    .swiper-slide {
        position: relative;
        overflow: hidden;
        .index-banner-wrapper {
            @include setMinRwd{
                margin: get-vw(150px) auto get-vw(350px);
            }
            @include setWidthRwd(1100px,1300px){
                margin-bottom: get-vw(400px);
            }
        }
        
        @include setStyleRwd(1100px){
            min-height: get-vw(1024px);
        }

        .img-container {
            @extend %setBack;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding-bottom: 40%;
            @include mobile{
                position: static;
                height: 200px;
            }
        }
    }

    .content{
        width: 100%;
        margin: auto;
        justify-content: flex-end;
        flex-direction: row;
        @include setMinRwd{
            max-width: get-vw(1400px);
        }
        
        @include pad1080{
            max-width: get-vw(1700px);
            margin-top: 3rem;
        }

        @include mobile{
            max-width:100%;
            flex-direction: column;
            background: #f0f4f3;
            padding: 1rem 1rem 5rem;
            margin-top: 0;
        }
    }

    .swiper-pagination{
        .swiper-pagination-bullet{
            width: 15px;
            height: 15px;
            opacity: 1;
            background-color: #fff;
            border: #192563 2px solid;
            &-active{
                background-color: #192563;
            }
        }
    
        @include setMinRwd{
            max-width: 20rem;
            bottom: vmaxCalc(15) !important;
            left: auto !important;
            right: get-vw(275px);
            text-align: left;
        }

        @include setStyleRwd(1480px){
            right: get-vw(290px);
        }

        @include setWidthRwd(1025px,1100px){
            right: get-vw(140px);
        }

        @include pad1024{
            bottom: get-vw-mobile(100px) !important;
            max-width: 45%;
            margin-left: auto;
        }

        @include pad{
            bottom: get-vw-mobile(30px) !important;
            max-width: 60%;
        }

        @include mobile{
            bottom: get-vw-mobile(50px) !important;
            max-width: 100%;
            margin: 0;
        }
    }

    .text-wrapper {
        overflow: hidden;
        display: inline-flex;
        .text-inner .sub-title,
        .text-inner .title,
        .text-inner .description{
            transform: translateY(50vw);
            transition: all ease 1s;
        }
    }

    //   /*active states*/
    .swiper-slide-active.active .text-inner .sub-title,
    .swiper-slide-active.active .text-inner .title,
    .swiper-slide-active.active .text-inner .description {
        transform: translateY(0);
        opacity: 1;
    }
    
    //   /*delays*/
    .swiper-slide.active .text-inner .sub-title {
        transition-delay: 0.05s;
    }
    
    .swiper-slide.active .text-inner .title {
        transition-delay: 0.1s;
    }
    
    .swiper-slide.active .text-inner .description {
        transition-delay: 0.15s;
    }
}

.index{
    &-content{
        padding: 120px 2rem 60px;
        @include mobile{
            padding-top: 50px;
        }
    }

    // Banner
    &-banner{
        @include mobile{
            img{
                height: 200px;
                object-fit: cover;
                object-position: center
            }
        }

        &-title{
            .en {
                &,
                b {
                    color: #fff;
                    @include setMaxRwd{
                        color:#000
                    }
                }
            }
            @include mobile{
                *{
                    color: #374a6f !important;
                    word-break: break-word;
                }
                .sub-title{
                    font-size: get-vw-mobile(40px);
                }
            }
        }
    }

    // 公司介紹
    &-intro-bg{
        padding-top: 0rem;
        margin-top: 1rem;
        z-index: 1;
        &::before{
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            min-height: get-vw(912px);
            position: absolute;
            top: get-vw(-240px);
            left: 0;
            z-index: 0;
            @extend %setBack;
            background-image: url('../images/index-intro-bg.png');
            pointer-events: none;

            @include setWidthRwd(1300px,1500px){
                top: get-vw(-360px);
                min-height: get-vw(1100px);
            }
        
            @include setStyleRwd(1500px){
                min-height: get-vw(1250px);
                top: get-vw(-450px);
            }
        
            @include setStyleRwd(1480px){
                background-position: right;
                top: get-vw(-350px);
            }
            
            @include pad1024{
                top: get-vw(-390px);
                min-height: get-vw(1700px);
            }
            
            @include mobile{
                height: auto;
                top: get-vw-mobile(-185px);
                background-image: url('../images/index-intro-bg-mobile.png');
                background-position: top;
                padding-bottom: get-vw-mobile(1400px);
            }
        }

        @include setMinRwd{
            margin-top: 4rem;
        }

        @include setWidthRwd(1300px,1500px){
            padding-top: 4em;
        }

        @include mobile{
            padding-top: 2.5rem;
            margin-top: 0;
        }
    }

    // 產品介紹
    &-pro-bg{
        z-index: 10;
        &::before{
            content: '';
            display: block;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: left;
            background-image: url('../images/index-proIntro-bg01.jpg');
            width: 100%;
            height: 0;
            padding-bottom: 54%;
            position: absolute;
            // top: -4rem;
            top: 9rem;
            left: 0;
            z-index: 0;
        }

        .img-circle{
            display: inline-block;
            width: get-vw(210px);
            height: get-vw(210px);
            position: absolute;
            right: get-vw(70px);
            top: get-vw(-70px);
            bottom: 1px;
            z-index: 4;
            animation: moveCircle 1.5s linear infinite both;
            @include pad{
                width: get-vw-mobile(210px);
                height: get-vw-mobile(210px);
                top: get-vw-mobile(-100px);
            }
        }

        @include setMinRwd{
            padding-right: 0;
            padding-bottom: 20px;

            .img-name{
                margin-top: get-vw(-140px);
            }
        }

        @include setWidthRwd(1100px,1600px){
            .img-name{
                margin-top: get-vw(-60px);
            }
        }

        @include setWidthRwd(1025px,1390px){
            .img-name {
                margin-top: get-vw(-160px);
            }
        }

        @include setWidthRwd(1025px,1100px){
            padding-top: 0;
        }

        @include pad1024{
            padding-left: 0;
            padding-right: 0;
            .img-name{
                margin-top: 2rem;
                text-align: center;
            }
            
            .block-title-box{
                margin-left: 1rem;
            }
        }

        @include mobile{
            &::before{
                display: none;
            }
        }
        
    }

    // 產品大區塊
    &-pro-content{
        display: flex;
        margin-top: 2rem;

        // 產品圖片介紹(圓弧形)
        .pro-introImg{
            flex: 1;
            &,
            img{
                position: relative;
            }
        }

        // 產品資訊
        .pro{
            &-info{
                display: flex;
                flex-grow: 2;
                margin-top: 2rem;
            }

            &-list{
                .title{
                    @include fontSize(30px);
                    margin-bottom: 15px;
                    &::before{
                        content: '';
                        display: block;
                        width: 44px;
                        height: 44px;
                        @extend %setBack;
                        background-image: url('../images/icon_doubleCircle.png');
                        position: absolute;
                        left: 0;
                        top: 2.5px;
                    }
                    @include setWidthRwd(760px,1100px){
                        @include fontSize(27px);
                    }
                }

                .list{
                    @include fontSize(24px);
                    color:#3f3f3f;
                    line-height: 1.5;
                    p{
                        margin-bottom: 5px;
                    }
                    @include setWidthRwd(1101px,1380px){
                        @include fontSize(20px);
                    }
                    @include setWidthRwd(760px,1100px){
                        @include fontSize(18px);
                    }
                }
                
                .title,
                .list{
                    padding-left: 60px;
                }
            }

            &-img{
                text-align: center;
                @include setWidthRwd(700px,1100px){
                    flex: 1;
                }
            }
        }

        .btn-wrap{
            margin-top: 2rem;
            @include setWidthRwd(700px,1100px){
                padding-left: 3.5rem;
            }
        }

        &.left{
            z-index: 4;
            .pro-introImg{
                z-index: 1;
                &::before{
                    content: '';
                    display: block;
                    @extend %setBack;
                }
            }
            .pro-info{
                padding-right: 5px;
            }
        }

        &.right{
            z-index: 2;
            &::before{
                content:'';
                @extend %setBack;
                background-size: contain;
                background-image: url('../images/index-proIntro-bg02.png');
                width: get-vw(1120px);
                height: get-vw(1114px);
                position: absolute;
                top: 0;
                right: -25rem;
                z-index: -1;
                opacity: 0.68;
            }
            .pro-introImg{
                order:1;
            }
            .pro-info{
                padding-left: 5px;
            }
        }

        @include setMinRwd{
            .pro-introImg{
                flex: none;
                width: get-vw(880px);
            }

            &.left{
                .pro-introImg{
                    left: -8rem;
                    &::before{
                        background-image: url('../images/index-proIntro-img01-bg.png');
                        position: absolute;
                        top: -1rem;
                        left: 3rem;
                        width: 100%;
                        height: 0;
                        padding-bottom: 100%;
                        z-index: 0;
                    }
                }
                
                .pro-info{
                    margin-left: get-vw(-60px);
                }
            }

            &.right{
                padding-top: 8rem;
                margin-top: get-vw(-800px);
                padding-bottom: get-vw(100px);
                align-items: flex-end;
                justify-content: flex-end;
                overflow: hidden;
                .pro-introImg{
                    top: get-vw(100px);
                    left: auto;
                    right: get-vw(-172px);
                }

                .pro-img{
                    order:-1;
                    margin-right: 0rem;
                    margin-bottom: get-vw(-60px);
                }

                .pro-info{
                    align-items: flex-end;
                    justify-content: flex-end;
                    margin-right: get-vw(-150px);
                    z-index: 5;
                }
            }
        }

        @include setWidthRwd(1301px,1600px){
            &.right{
                margin-top: get-vw(-600px);
                padding-bottom: get-vw(200px);
                &::before {
                    top: -5rem;
                    right: -20rem;
                    padding-bottom: 75%;
                    height: 0;
                }
            }
        }

        @include setStyleRwd(1400px) {
            &.right{
                &::before {
                    top: 4rem;
                    right: -19rem;
                }
            }
        }

        @include setWidthRwd(1025px,1390px){
            .pro-list{
                flex-grow: 1;
            }
            
            &.right{
                margin-top: get-vw(-400px);
                padding-bottom: get-vw(0px);
                align-items: flex-start;
                &::before {
                    top: 2rem;
                    right: -16rem;
                    padding-bottom: 55%;
                }
                .pro-introImg {
                    top: get-vw(0px)
                }
                .pro-img {
                    flex-grow: 4;
                }
            }
        }

        @include setWidthRwd(1025px,1250px){
            &.right{
                margin-top: get-vw(-100px);
            }
        }

        @include setWidthRwd(1025px,1200px){
            .pro-introImg {
                max-width: 27rem;
            }
            
            &.right{
                &::before{
                    top: 2rem;
                    right: -16rem;
                    height: 0;
                    padding-bottom: 50%;
                }
            }
        }

        @include pad1024{
            overflow: hidden;

            .pro-list{
                flex-grow: 1;
            }

            &.left{
                .pro-introImg{
                    left: -5rem;
                }
            }

            &.right{
                padding-left: 2rem;
                &::before{
                    height: 0;
                    padding-bottom: 75%;
                    right: 0;
                }
                .pro-introImg{
                    right: -5rem
                }
                .pro-info {
                    margin-right: -5rem;
                }
            }
        }

        @include setWidthRwd(700px,1100px){
            .pro-list{ flex-grow: 0; }
        }

        @include mobile{
            .pro-introImg{
                flex: none;
                width: 200px;
            }
            .pro-img{
                order:-1
            }

            .pro-info{
                flex-direction: column;

                .title{
                    @include fontSize(25px);
                }

                .list{
                    @include fontSize(20px);
                }
            }

            .btn-wrap{
                justify-content: center !important
            }
            
            &.left{
                flex-direction: column;
                &::before{
                    background-size: cover;
                    padding-bottom: 100%;
                }

                .pro-introImg{
                    left: -2rem;
                }

                .pro-img {
                    position: absolute;
                    top: -50vw;
                    left: 40%;
                }

                .pro-info {
                    padding-left: 10px;
                }
            }
            
            &.right{
                &::before{
                    display: none;
                }
                .pro-introImg{
                    position: absolute;
                    right: -3rem !important;
                    left: auto !important;
                }
                .pro-info{
                    margin-right: -4rem;
                }
                .pro-img {
                    width: 50%;
                }
                .btn-wrap {
                    justify-content: flex-start !important;
                    padding-left: 60px;
                }
            }
        }
    }

    // 影片介紹
    &-video-bg{
        @extend %setBack;
        background-position: top;
        background-image: url('../images/index-video-bg.png');
    }

    &-video-list{
        margin-top: 1rem;
    }
}

.intro{
    &-content{
        @extend %setFlex;

        @include setMinRwd{
            align-items: flex-end;
            margin-top: -4rem;
        }

        @include pad1080{
            flex-direction: column;
        }
    }

    &-txtWrap{
        flex: 1;
        .title{
            margin-top: 2rem;
            line-height: 1.5;
            font-size: 1.25rem;
        }

        .txt{
            margin:1.25rem 0 3rem;
            @include fontSize(18px);
            font-weight: 100;
        }

        .btn{
            border-radius: 30px;
            padding-left: 4rem;
            padding-right: 4rem;
            padding-top: 0.65rem;
            padding-bottom: 0.65rem;
            border-width: 2px;
        }
        @include pad1080{
            .title{
                margin-top: 1rem;
            }

            .txt{
                margin:1rem 0;
            }
        }
    }

    &-imgWrap{
        flex: 1.75;
        text-align: center;
    }
}

.video{
    &-box{
        box-shadow: 0 0 10px rgba(0,0,0, 0.5)
    }
    
    &-txt{
        @extend %setFlex;
        justify-content:space-between;
        @include fontSize(20px);
        color:#3d3e3e;
        margin-top: 1rem;
    }
}

.banner-TopArrow-list{
    position: absolute;
    bottom: get-vw(100px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    width: 105px;
    .banner-TopArrow {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        animation: jump 1.5s infinite;
    }

    @include pad1024{
        display: none;
    }
}

@keyframes jump {
    0% {
        bottom: 0;
    }
    50% {
        bottom: 10px;
    }
    100% {
        bottom: 0;
    }
}

@keyframes moveCircle {
    0%,
    100% {
        transform: translateY(-15%);
    }
    50% {
        transform: translateY(-5%);
    }
}