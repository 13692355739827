.equip-bg{
    @include setBackSet(bottom,auto);
    background-image: url('../images/bg-equip.jpg');
    padding-top: 50px;
}

.equip{
    &-content-intro{
        display: flex;
        width: 100%;
        background-color: #fff;
        padding: 3rem 2rem 0.25rem;
        
        @include pad1024{
            flex-direction: column;
            padding: 1.5rem 1.25rem 0.25rem;
        }
    }

    &-introList{
        flex:1;
        @extend %setFlex;
        align-items: flex-start;
        padding:0.5rem 0;
        color:$colorList;
        @include fontSize(19px);

        p{
            margin-bottom: 10px;
        }

        li{
            position: relative;
            border-bottom: 1px dashed #656565;
            margin-bottom: 1rem;
            width: calc(25% - 2rem);
            margin-right: 2rem;
            padding-left: 2rem;

            &::before{
                content: '';
                display: block;
                width: 17px;
                height: 17px;
                @extend %setBack;
                background-image: url('../images/icon-list.png');
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -12px;
            }
        }

        @include setMinRwd{
            margin-left: 3rem;
            li{
                margin-bottom: 45px;
                &:nth-child(4n-4){
                    width: 25%;
                    margin-right: 0;
                    padding-right: 0;
                }
            }
        }

        @include setStyleRwd(1500px){
            li{
                width: calc(33% - 2rem) !important;
                margin-right: 2rem !important;
            }
        }

        @include setStyleRwd(1300px){
            li{
                width: calc(50% - 0.5rem) !important;
                margin-right: 0.5rem !important;
            }
        }

        @include pad1024{
            @include fontSize(17px);
            li{
                width: calc(100% - 0.5rem) !important;
                padding-left: 1.5rem;
            }
        }

        @include mobile{
            li{
                width: calc(100% - 0.5rem);
            }
        }
    }

    &-content-list{
        padding-right: 0;
        .block-titleImg-box{
            margin-top:1rem;
            margin-bottom:0rem;
        }
    }

    &-instruments-list{
        @extend %setFlex;
        justify-content: space-between;
        width: 100%;
        li{
            width: calc((100% / 2) - 1rem);
            // margin-left: 1rem;
            margin-bottom: 0.5rem;
            &:last-child{
                margin-right: 0;
            }
        }
    }
}

.equip-swiper{
    max-width: 75rem;
    margin: 1rem auto;
    overflow: hidden;

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 500ms;
        transform: scale(0.7) !important;
        &::after{
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba($color: #000000, $alpha: 0.6);
        }
        &-active,
        &-duplicate-active{
            transform: scale(1.15) !important;
            &::after{
                opacity: 0;
            }
        }
        
        @include mobile{
            &,
            &-active,
            &-duplicate-active{
                transform: scale(1) !important;
            }
        }
    }

    .swiper-button{
        &-next,
        &-prev{
            color: #aeaeae;
            &::after{
                font-size: 2rem;
            }
        }

        &-prev{
            left: 0;
        }

        &-next{
            right: 0;
        }
    }

    &-container{
        @include setMinRwd{
            padding:2rem;
        }
    }
}