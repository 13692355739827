// 六角形
.hexagon {
    position: relative;
    margin: 100px auto;
    width: 190px;
    height: 110px;
    // background-image: url('https://i.imgur.com/IEiqEV0.jpg');
    background-position: center center;
    z-index: 1;
    line-height: 110px;
    text-align: center;
    font-family: sans-serif;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 6px;
    color: #fff;
    color: rgba(255,255,255,.5);
    cursor: pointer;
    transition: color 500ms ease, text-shadow 500ms ease;
    :hover {
        color: #fff;
        text-shadow: 0 0 10px white;
    }


    .face1,
    .face2 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: inherit;
        z-index: -1;
        backface-visibility: hidden;
        &::before {
            content: '';
            position: absolute;
            width: 220px;
            height: 220px;
            background: inherit;
        }
    }
    .face1 {
        transform: rotate(60deg);
        &::before {
            left: 0;
            transform-origin: left top;
            transform: rotate(-60deg) translate(-110px, 0);
        }
    }
    .face2 { 
        transform: rotate(-60deg);
        &::before {
            right: 0;
            transform-origin: right top;
            transform: rotate(60deg) translate(110px, 0);
        }    
    }
} 

// <div class="hexagon">
//     HEXAGON
//     <div class="face1"></div>
//     <div class="face2"></div>
// </div>

.hex-grid__item {
    float: left;
    width: 200px;
    // width: calc(100vw / 6);
    -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    border-left: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: translateZ(0);
}

.hex-grid__media {
    display: block;
    width: 100%;
}

// <div class="hex-grid__item">
//     <img class="hex-grid__media" src="https://unsplash.it/200/230?image=318" alt="">
// </div>

$middle-color: transparent;
$border-color: white;
$element-size: 200px;

.hexagon-style {
	position: relative;
	overflow: hidden;
	width: $element-size;
	// Fixed height is not required, but this allows for a same sized hexagon with centered content
	height: $element-size;

	img {
		display: block;
		width: 100%;
		position: absolute;
		z-index: 0;
		
		// Center the content verticaly
		top: 50%;
		transform: translateY(-50%);
	}

	&:before,
	&:after {
		content: "";
		position: absolute;
		box-sizing: border-box;
		top: 0;
		width: 100%;
		height: 0;
		z-index: 5;

		border-left: ($element-size * 0.5) solid $border-color;
		border-right: ($element-size * 0.5) solid $border-color;
		// Border height
		// tip: divide by 2 to make it a square
		border-bottom: ($element-size * 0.25) solid $middle-color;
	}

	&:after {
		// Rotate for the bottom corners
		transform: rotate(-180deg);
		top: auto;
		bottom: 0;
	}
}

// <div class="hexagon-style">
//     <img src="//via.placeholder.com/200x200" alt="">
// </div>

// Hexagons with rounded corners using `clip-path`
.hexagons-list{
    position: relative;

    // 文字位置
    .hexagons-txt{
        width: 100%;
        text-align: center;
        @include fontSize(18px);
        margin-top: 0.5rem;
        z-index: 2;
    }
    
    .hexagons {
        display: flex;
        box-shadow: 0px 25px 30px -30px rgba(0, 0, 0, 0.5);
        transform: scale(1);
        overflow: hidden;
        .img{
            position: relative;
            z-index: 0;
            display: inline-block;
            width: 100%;
            height: 0;
            padding-bottom: 132%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-color: teal;
            cursor: pointer;
            &,
            &::after{
                transition: all 0.3s ease-out;
            }
            &::after {
                content: "";
                background-color: rgba(0, 0, 0, 0.5);
                opacity: 0.35;
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                border-radius: 0;
            }
        }
    }
    
    @include setMinRwd{
        // Hover
        &:hover{
            .hexagons {
                .img{
                    transform: scale(1.05);
                    &::after {
                        opacity: 0;
                    }
                }
            }
        }
    }

    @include pad{
        .hexagons{
            margin-bottom: -0.5rem;
        }
        
        // 文字位置
        .hexagons-txt{
            @include fontSize(17px);
        }
    }
}
  