.quality-bg{
    @extend %setBack;
    background-image: url('../images/bg-quality.jpg');
    padding-top: 120px;
    padding-bottom: 120px;
}

.quality{
    &-list{
        @include setMinRwd{
            max-width: 72.5%;
        }
    }
}