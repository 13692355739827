// 聯絡我們
.contact-bg{
    @extend %setBack;
    background-image: url('../images/bg-contact.jpg');
    padding-top: 120px;
    padding-bottom: 120px;
}


.contact{
    &-block {
        position: relative;
    }
    &-info{
        .txt{
            color:$colorSubsCont;
            @include fontSize(20px);
            font-weight: 500;
            line-height: 2.25;
            font-weight: 600;
            width: 100%;
            border: rgba(0,0,0,0) 6px solid;
            box-shadow: 0 0 10px rgba(0,0,0,.5);
            p{
                margin-bottom: 0;
            }
            @include setMinRwd{
               width: 460px;
            }
            @include pad1024{
                @include fontSize(18px);
                margin-top: 1rem;
            }
        }
    }
    &-form {
        background: #fff;
        padding: 1rem;
        border:#000 1px solid;
        box-shadow: 0 0 10px 7px rgba(0,0,0,.15);
        legend{
            line-height:1.5;
            @include fontSize(16px);
            margin-bottom: 1rem;
        }

        label{
            margin-bottom: 0;
        }

        .form-control{
            &,
            &:hover,
            &:focus{
                border-width:0 0 1px 0;
                border-style: solid;
                border-color: #000000;
                outline: none;
                box-shadow: none;
                background-color: #fff;
            }
        }

        .btn-wrap{
            li{
                display: flex;
                justify-content: center;
                width:100%;
            }
            @include mobile{
                flex-direction: column;
                li{
                    margin-bottom: 10px;
                }
            }
        }
        @include setMinRwd{
            padding: 2rem;
            max-width: 62.5%;
            legend{
                margin-bottom: 2.75rem;
            }
        }
        @include setStyleMinRwd(1400px){
            padding: 85px 130px;
        }
        @include pad1024{
            margin-top: 2rem;
            max-width: 100%;
        }
    }
    @include setMaxRwd{
        display: block;
    }
}