@charset "UTF-8";
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap");
@import url("//fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("//fonts.googleapis.com/css2?family=Inter:wght@200&display=swap");
a, body {
  color: #000;
  font-size: 16px;
  font-family: "Noto Sans TC", "Microsoft JhengHei", "微軟正黑體", "Arial", sans-serif;
  -webkit-text-stroke: 0.1px;
  line-height: 1.8;
  font-weight: 400;
}

.sprite, input, form, body, html {
  margin: 0px;
  padding: 0px;
}

.contact-bg, .equip-introList li::before, .quality-bg, .pro-bg, .swiper--progress .swiper-button-prev,
.swiper--progress .swiper-button-next, .index-video-bg, .index-pro-content.right::before, .index-pro-content.left .pro-introImg::before, .index-pro-content .pro-list .title::before, .index-intro-bg::before, .image-slider .swiper-slide .img-container {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.equip-instruments-list, .equip-introList, .video-txt, .intro-content, .content {
  display: flex;
  flex-wrap: wrap;
}

.content-title .wrapper, .btn-wrap, .radio_style, .nav-fostrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.noJS > div,
.noJS > ul {
  display: none;
}

.noJS > noscript {
  display: block;
  width: 100%;
  line-height: 10em;
}

* {
  box-sizing: border-box;
}

html {
  overflow: -moz-scrollbars-vertical;
}

body {
  background: #fff;
  visibility: visible;
}
body.fixed {
  overflow: hidden;
}

div {
  position: relative;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.sh_sans {
  font-family: "source-han-sans-traditional", "微軟正黑體", sans-serif;
}

.sh_serif {
  font-family: "source-han-serif-tc", serif;
}

form {
  font-size: 16px;
}

label {
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
}

input {
  -webkit-autofill: unset;
  font-size: 16px;
  background-color: #eeeeee;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fcfcfc inset;
}
input:focus {
  outline: 0;
}
input[type=text], input[type=password] {
  vertical-align: top;
}
input[type=radio], input[type=checkbox] {
  margin-right: 5px;
  cursor: pointer;
  vertical-align: middle;
}
input[type=number]::-webkit-inner-spin-button,
input [type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
input.hid {
  display: none;
}

a:focus,
input:focus,
select:focus,
button:focus {
  box-shadow: none !important;
  outline: none;
}

select {
  border: 0px;
  outline: none;
}

textarea {
  padding: 5px;
  border: 1px solid rgba(216, 216, 216, 0.8);
  box-sizing: border-box;
  resize: none;
  font-size: 16px;
  background-color: #eeeeee;
}

a {
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: none;
  color: currentColor;
}

img {
  max-width: 100%;
}

.sprite {
  fill: #0d439b;
  vertical-align: top;
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.font-button .far,
.font-button .fas {
  color: #666;
  transition: 0.08s cubic-bezier(0.37, 0.99, 0.92, 0.96);
}
.font-button:hover .far,
.font-button:hover .fas {
  color: #0d439b;
}

.zero-size {
  font-size: 0px;
  letter-spacing: 0px;
  word-spacing: 0px;
}

.required {
  color: #0d439b;
  margin: 0 0 0 8px;
}

.red {
  color: #ff0016;
}

.space {
  display: inline-block;
  width: 7px;
}

.clear {
  clear: both;
  height: 0px;
  line-height: 0px;
  font-size: 0px;
  overflow: hidden;
}

.ellipsis, .card-wrap.index-card-wrap .card__title p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hide {
  display: none !important;
}
@media (max-width: 1024px) {
  .hide {
    display: block !important;
  }
}

@keyframes menushow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes visitsLoad {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  30% {
    transform: translate3d(-70%, 0, 0);
  }
  80% {
    transform: translate3d(-20%, 0, 0);
  }
  95% {
    transform: translate3d(-5%, 0, 0);
  }
  100% {
    transform: translate3d(0%, 0, 0);
  }
}
@keyframes visitsShow {
  0% {
    opacity: 0;
    transform: translate3d(0, 45px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes moveCircle {
  0%, 100% {
    transform: translateY(-10%);
  }
  50% {
    transform: translateY(-5%);
  }
}
.table {
  border-top: 1px solid #c8dae8;
  border-right: 1px solid #c8dae8;
  border-left: 1px solid #c8dae8;
}

.input {
  width: 100%;
}

.textarea {
  width: 100%;
  height: 100px;
}

.searchBar {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.icon {
  display: inline-block;
  vertical-align: middle;
}

.wrapper-1800 {
  width: 100%;
  max-width: 1800px;
  margin: auto;
}

.wrapper-1750 {
  width: 100%;
  max-width: 1750px;
  margin: auto;
}

.wrapper-1700 {
  width: 100%;
  max-width: 1700px;
  margin: auto;
}

.wrapper-1650 {
  width: 100%;
  max-width: 1650px;
  margin: auto;
}

.wrapper {
  width: 100%;
  max-width: 1600px;
  margin: auto;
}

.wrapper-1550 {
  width: 100%;
  max-width: 1550px;
  margin: auto;
}

.wrapper-1500 {
  width: 100%;
  max-width: 1500px;
  margin: auto;
}

.wrapper-1450 {
  width: 100%;
  max-width: 1450px;
  margin: auto;
}

.wrapper-1400 {
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.wrapper-1350 {
  width: 100%;
  max-width: 1350px;
  margin: auto;
}

.wrapper-1300 {
  width: 100%;
  max-width: 1300px;
  margin: auto;
}

.wrapper-1250 {
  width: 100%;
  max-width: 1250px;
  margin: auto;
}

.wrapper-1200 {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.wrapper-1100 {
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.wrapper-1000 {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.wrapper-900 {
  width: 100%;
  max-width: 900px;
  margin: auto;
}

.wrapper-800 {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.detail_label {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  padding: 0 6px;
  height: 20px;
  text-align: center;
  background-color: #fff;
  line-height: 20px;
  vertical-align: middle;
  font: 13px/25px Arial;
  border: 1px solid #fff;
}
.detail_label.fc-LightSalmon {
  border-color: #e9a3a8;
  color: #e9a3a8;
  line-height: 20px;
}

.nav-header {
  position: sticky;
  top: 0;
  z-index: 10000;
  background: #fff;
}

.nav-fostrap {
  justify-content: space-between;
  background: #fff;
}
.nav-fostrap ul {
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: block;
  z-index: 2;
}
@media (min-width: 1025px) {
  .nav-fostrap ul {
    text-align: right;
  }
}
.nav-fostrap li {
  list-style-type: none;
  margin: 0;
  display: inline-block;
  position: relative;
}
.nav-fostrap li a {
  position: relative;
  z-index: 1;
  font-size: 20.5px;
  font-size: 1.28125rem;
  color: #000;
  display: inline-block;
  outline: 0;
}
.nav-fostrap li a::after {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  background-color: #b0cbe2;
  position: absolute;
  top: 0;
  left: -0.5rem;
  border-radius: 50%;
  transition: 0.25s;
  opacity: 0;
  z-index: -1;
}
.nav-fostrap li ul.dropdown {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 160px;
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -80px;
  padding-top: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
}
.nav-fostrap li ul.dropdown li {
  padding-top: 0.75rem;
  padding-bottom: 1.5rem;
}
.nav-fostrap li ul.dropdown li a {
  padding: 0 1rem;
  font-weight: 400;
  color: #000;
  line-height: 1;
  font-size: 20px;
  font-size: 1.25rem;
}
.nav-fostrap li ul.dropdown li a::after {
  display: none;
}
.nav-fostrap li ul.dropdown li a:hover {
  color: #0d439b;
}
.nav-fostrap li ul.dropdown li ~ li a {
  border-left: 1px solid #000;
}
.nav-fostrap li:hover ul.dropdown {
  opacity: 1;
  visibility: visible;
}
.nav-fostrap li:hover a, .nav-fostrap li.active a {
  color: #0d439b;
}
.nav-fostrap li:hover a::after, .nav-fostrap li.active a::after {
  opacity: 1;
}
.nav-fostrap-link {
  display: flex;
  align-items: center;
  flex: 1;
}
@media (max-width: 1024px) {
  .nav-fostrap-link {
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
  }
  .nav-fostrap-link ul,
.nav-fostrap-link .dropdown-lang {
    width: 100%;
  }
  .nav-fostrap-link .dropdown-lang {
    margin-top: 15px;
  }
  .nav-fostrap-link .dropdown-lang .dropdown-toggle {
    display: none;
  }
  .nav-fostrap-link .dropdown-lang .dropdown-menu {
    display: flex;
    width: 100%;
    padding: 0;
  }
  .nav-fostrap-link .dropdown-lang .dropdown-item {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.15);
  }
  .nav-fostrap-link .dropdown-lang .dropdown-item:last-child {
    border-right: none;
  }
}
@media (min-width: 1025px) {
  .nav-fostrap {
    padding-top: 0.5rem;
  }
  .nav-fostrap li {
    padding: 15px 2% 15px 3.5%;
  }
  .nav-fostrap li:first-child {
    padding-left: 0;
  }
}
@media (min-width: 1005px) and (max-width: 1600px) {
  .nav-fostrap {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (min-width: 1005px) and (max-width: 1200px) {
  .nav-fostrap li a {
    font-size: 16px;
  }
}

.nav-fostrap-menuBg {
  position: absolute;
  left: 0;
  top: 5rem;
  width: 100%;
  height: 65px;
  background: rgba(212, 232, 206, 0.37);
  z-index: 1;
  display: none;
}
.nav-fostrap-menuBg.active {
  display: block;
}

.arrow-down {
  position: absolute;
  right: 1rem;
}

.title-mobile {
  display: none;
}

@media (min-width: 1025px) {
  .container {
    width: 96%;
  }
  .nav-bg-fostrap,
.arrow-down {
    display: none;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .nav-logo {
    display: block;
    width: 30vw;
  }
}
@media (max-width: 1024px) {
  .nav-fostrap {
    background: #fff;
    width: 55vw;
    height: 100%;
    display: block;
    position: fixed;
    left: -100%;
    top: 0px;
    transition: left 0.25s ease;
    z-index: 12;
  }
  .nav-fostrap li a::after {
    display: none !important;
  }
  .title-mobile {
    display: block;
    max-width: 70vw;
  }
  .nav-logo {
    display: block;
    margin: auto;
  }
  .mobile-closeBtn {
    width: 45px;
    height: 45px;
    background-color: #000;
    text-align: center;
    color: #fff;
    position: absolute;
    right: -45px;
    top: -74px;
    z-index: 6;
    font-size: 25px;
    font-size: 1.5625rem;
  }
  .mobile-closeBtn a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .nav-fostrap.visible {
    left: 0px;
    transition: left 0.25s ease;
  }
  .nav-bg-fostrap {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    min-height: 65px;
    margin: 0;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    z-index: 5;
  }
  .navbar-fostrapClick {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    padding: 12px;
  }
  .navbar-fostrapClick .icon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .navbar-fostrapClick span {
    height: 2px;
    background: #0d439b;
    margin: 2px 5px;
    display: block;
    width: 20px;
  }
  .navbar-fostrapClick span:nth-child(2), .navbar-fostrapClick span:nth-child(3) {
    width: 20px;
  }
  .navbar-fostrapClick .txt {
    font-size: 14px;
    font-size: 0.875rem;
  }
  .nav-fostrap ul {
    padding-top: 5px;
  }
  .nav-fostrap li {
    display: block;
    border-bottom: #eee 1px solid;
  }
  .nav-fostrap li a {
    display: block;
    font-size: 16px;
    font-size: 1rem;
    padding: 6px 12px;
  }
  .nav-fostrap li:first-child:hover a {
    border-radius: 0;
  }
  .nav-fostrap li ul.dropdown {
    position: static;
    display: none !important;
    margin-left: 0;
  }
  .nav-fostrap li ul.dropdown.active {
    display: block !important;
    opacity: 1;
    visibility: visible;
  }
  .nav-fostrap li ul.dropdown li {
    padding: 0;
    border-bottom-color: #0d439b;
  }
  .nav-fostrap li ul.dropdown li a {
    background: #092f6c;
    border-bottom: none;
    color: #fff !important;
  }
  .nav-fostrap li:hover a, .nav-fostrap li.active a {
    background: #0d439b;
    color: #fff !important;
  }
  .nav-fostrap li a.active {
    background: #0b3983;
    color: #fff !important;
  }
  .nav-fostrap li a.active .arrow-down {
    transform: rotate(180deg);
  }
  .nav-fostrap li ul.dropdown li a {
    padding: 15px 10px 15px 30px;
  }
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.nav-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: none;
  opacity: 0;
}
.nav-overlay.show {
  display: block;
  opacity: 1;
}

.dropdown-lang {
  flex: none;
}
.dropdown-lang .dropdown-toggle {
  display: flex;
  align-items: center;
  border-radius: 20px;
  border: #0d439b 1px solid;
  padding: 0.2em 0.75em 0.25em;
  color: #0d439b;
  line-height: 27px;
  font-size: 16px;
  font-size: 1rem;
}
.dropdown-lang .dropdown-toggle::after {
  display: none;
}
.dropdown-lang .dropdown-toggle .icon {
  flex: none;
  margin-right: 0.45rem;
  margin-top: -2px;
}
.dropdown-lang .dropdown-menu {
  min-width: 5.75rem;
  border-color: #0d439b;
  padding: 0;
  margin-top: 0.25rem;
  border-radius: 1rem;
  overflow: hidden;
}
.dropdown-lang .dropdown-menu .dropdown-item {
  text-align: center;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
}
@media (min-width: 1025px) {
  .dropdown-lang .dropdown-menu .dropdown-item:hover {
    background-color: #0d439b;
    color: #fff;
  }
  .dropdown-lang .dropdown-menu .dropdown-item ~ .dropdown-item {
    border-top: 1px solid #0d439b;
  }
}
@media (min-width: 1025px) {
  .dropdown-lang {
    margin-left: 10px;
  }
}

footer {
  background: #143b7b;
  color: #fff;
  padding: 25px 0 35px;
}
footer .wrapper {
  display: flex;
}
@media (min-width: 1025px) {
  footer .wrapper {
    max-width: 95%;
  }
}
@media (max-width: 1600px) {
  footer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (min-width: 800px) and (max-width: 1000px) {
  footer .wrapper {
    flex-wrap: wrap;
  }
  footer .divider {
    display: none;
  }
}
@media (max-width: 1080px) {
  footer {
    padding: 1rem 0.75rem;
  }
}
@media (max-width: 640px) {
  footer .wrapper {
    flex-direction: column;
  }
}

.divider {
  width: 2px;
  height: 100%;
  background: #bfb1a5;
}

.footer-logo {
  display: flex;
  align-items: center;
}
.footer-logo img {
  display: block;
  max-width: 100%;
}

.footer {
  display: flex;
}
.footer-info .list {
  display: flex;
  align-items: center;
}
.footer-info .list .img {
  width: 50px;
  flex: none;
  margin-right: 10px;
}
@media (max-width: 1080px) {
  .footer-info .list .img {
    margin-right: 5px;
  }
}
.footer-info .list small {
  display: block;
  color: #383838;
  margin-bottom: 3px;
  margin-top: 7px;
}
.footer-info .list .time {
  padding-right: 10px;
}
.footer-info .list .txt {
  letter-spacing: 1px;
  padding: 8px 0 0 10px;
  line-height: 1.5;
}
.footer-info .list .txt a {
  text-decoration: underline;
  margin-left: 5px;
}
.footer-info .list .txt p {
  align-items: center;
  margin-bottom: 5px;
  margin-right: 10px;
}
.footer-info .list .txt-big {
  font-size: 24px;
  font-size: 1.5rem;
  margin-bottom: 5px !important;
}
@media (min-width: 1025px) {
  .footer-info .list .txt,
.footer-info .list .txt p {
    display: flex;
    flex-wrap: wrap;
  }
  .footer-info .list .txt p {
    flex: none;
  }
  .footer-info .list .txt p > span {
    flex: none;
    padding-right: 5px;
  }
}
@media (min-width: 1400px) {
  .footer-info .list .txt p {
    margin-bottom: 0px;
    margin-right: 20px;
  }
  .footer-info .list .txt p:last-of-type {
    margin-right: 10px;
  }
}
@media (max-width: 1080px) {
  .footer-info {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .footer-info .list {
    align-items: flex-start !important;
  }
  .footer-info .list .txt {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    padding-top: 0;
  }
  .footer-info .list .txt p {
    line-height: 1.5;
    margin-left: 0 !important;
    margin-bottom: 10px;
  }
  .footer-info .list .txt-big {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.footer-social p {
  margin-bottom: 0;
}
@media (min-width: 1025px) {
  .footer-social {
    margin-top: 5px;
    margin-left: 20px;
    text-align: center;
  }
}
@media (max-width: 1024px) {
  .footer-social {
    margin-top: 10px;
  }
  .footer-social p {
    margin-left: 5px;
  }
}
.footer-social .social-icon {
  margin-bottom: 10px;
}
.footer.left, .footer.center, .footer.right {
  flex-grow: 1;
  max-width: 100%;
  display: inline-flex;
  align-items: flex-start;
}
.footer.left {
  justify-content: space-around;
}
@media (max-width: 810px) {
  .footer.left {
    flex-direction: column;
  }
}
@media (min-width: 1025px) {
  .footer.right {
    flex: none;
    padding-left: 60px;
  }
}
.footer.center .txt {
  line-height: 2.2;
}
.footer.center .txt p ~ p small {
  color: #10100f;
  font-size: 16px;
  font-size: 1rem;
}
@media (max-width: 1080px) {
  .footer.center .txt {
    line-height: 1.5;
  }
  .footer.center .txt p ~ p small span {
    display: block;
    margin-bottom: 5px;
  }
}
@media (min-width: 1025px) and (max-width: 1100px) {
  .footer.center {
    margin-left: 15px;
    margin-right: 5px;
  }
}
@media (min-width: 1025px) {
  .footer.left .list .txt p br {
    display: none;
  }
}

.iware {
  background-color: #0a2653;
  color: #fff;
  font-size: 13px;
  font-size: 73%;
  font-weight: 100;
  padding: 5px 8px 6px;
}
.iware a {
  color: #fff;
}
.iware a:hover {
  text-decoration: underline;
}

.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 10;
  transition: 0.3s;
}
.float-link .link-btn a .circle {
  background: #0d439b;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  margin-bottom: 5px;
}
.float-link .link-btn a .circle::before {
  content: "";
  display: block;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-radius: 100%;
  position: absolute;
  z-index: 1;
  left: 5px;
  top: 5px;
}
.float-link .link-btn a .circle::after {
  content: "\f077";
  font-family: "FontAwesome";
  display: block;
  font-size: 25px;
  font-size: 1.5625rem;
  color: #fff;
}
.float-link .link-btn a .txt {
  display: block;
  color: #0d439b;
  font-size: 17px;
  font-size: 1.0625rem;
  line-height: 1.2;
  text-align: center;
}
.float-link .link-btn a:hover {
  opacity: 0.65;
}
.float-link.active {
  right: 5px;
}
@media (max-width: 1600px) {
  .float-link .link-btn a span {
    font-size: 14px;
    font-size: 0.875rem;
  }
  .float-link.active {
    right: 10px;
  }
}
@media (max-width: 1080px) {
  .float-link {
    bottom: 10px;
  }
  .float-link .link-btn {
    flex: 1;
  }
  .float-link .link-btn br {
    display: none;
  }
  .float-link .link-btn a {
    width: 100%;
    height: 55px;
    border-radius: 0;
    margin: 0;
  }
  .float-link .link-btn a::before {
    display: none;
  }
  .float-link .link-btn a,
.float-link .link-btn a span {
    flex-direction: column !important;
  }
}

.cookies {
  display: none;
  width: 100%;
  position: fixed;
  background: white;
  border-top: 1px solid #aeadad;
  color: #333;
  font-size: 15px;
  font-size: 0.9375rem;
  font-size: 0.9375rem;
  text-align: center;
  bottom: 0;
  left: 0;
  z-index: 9999;
  padding: 0.5rem 1rem;
}
.cookies > p {
  font-weight: 400;
  line-height: 2;
}
.cookies > p a {
  text-decoration: underline !important;
  color: blue;
  font-weight: 500;
}
.cookies > p .btn {
  color: #fff;
  display: inline;
  font-size: 13px;
  font-size: 0.8125rem;
  text-decoration: none !important;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.cookies.display {
  display: block;
  animation: cookies 1s 1;
}
@media (max-width: 810px) {
  .cookies {
    width: calc(100% - 1em);
    margin: 0.5em;
    border-top: none;
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.close-cookies {
  transition: all 0.2s ease-out;
  transform: translate3d(0, 100%, 0);
}

@keyframes cookies {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0%, 0);
  }
}
article {
  width: 100%;
  font-size: 100%;
}
article iframe {
  width: 100%;
  height: 750px;
}
@media (max-width: 810px) {
  article iframe {
    height: 350px;
  }
}

.normal-content {
  background-color: #eeeeee;
  padding: 2em 1em;
  overflow: hidden;
}
@media (min-width: 1101px) and (max-width: 1600px) {
  .normal-content {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
@media (max-width: 1024px) {
  .normal-content {
    padding: 1rem 0.95rem !important;
  }
  .normal-content article {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media (min-width: 1025px) {
  .content-info {
    padding-top: 2rem;
    padding-right: 4.5rem;
  }
}
@media (max-width: 1024px) {
  .content-info .block-title-box {
    padding-left: 15px;
  }
}
.content-list {
  max-width: 100%;
  flex: 1;
}
@media (min-width: 1025px) {
  .content-list {
    margin-left: auto;
  }
}
@media (min-width: 1005px) and (max-width: 1200px) {
  .content-list {
    margin-left: 2rem;
  }
}
@media (max-width: 1024px) {
  .content {
    flex-direction: column;
  }
}

.text-main {
  color: #b0cbe2 !important;
}

.bg-second {
  background-color: #eeeeee !important;
}

.block-title-box {
  font-size: 45px;
  font-size: 2.8125rem;
  line-height: 1.25;
  margin-bottom: 1.25rem;
  z-index: 10;
}
.block-title-box p {
  margin-bottom: 0;
}
.block-title-box .txt {
  margin-top: 0.75rem;
  color: #374a6f !important;
  font-size: 22px !important;
  line-height: 1.8 !important;
}
.block-title-box .en {
  color: #e1e0e0;
  margin-bottom: 7px;
  font-family: "Inter";
}
.block-title-box .en b {
  color: #d5d5d5;
  font-family: "Noto Sans TC";
}
.block-title-box .en-big {
  color: #d0d5dc;
  font-size: 60px;
  font-size: 3.75rem;
}
@media (max-width: 1024px) {
  .block-title-box .en-big {
    font-size: 40px;
    font-size: 2.5rem;
  }
}
.block-title-box .en-small {
  font-weight: 600;
  font-size: 28px;
  font-size: 1.75rem;
  color: #0d439b;
}
.block-title-box .en-small b {
  color: #0160fb;
}
.block-title-box .ch {
  color: #2d456c;
  font-weight: 600;
  margin-top: 5px;
}
.block-title-box .ch .font {
  color: #0160f8;
}
.block-title-box .ch-small {
  color: #0d439b;
  font-size: 18px;
  font-size: 1.125rem;
}
.block-title-box .ch-small-gray {
  color: #939294;
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: 100;
}
.block-title-box .ch-medium {
  color: #374446;
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 100;
}
@media (max-width: 640px) {
  .block-title-box {
    margin-bottom: 0.25rem;
    font-size: 30px;
    font-size: 1.875rem;
  }
  .block-title-box p br {
    display: none;
  }
  .block-title-box p > b {
    padding-left: 5px;
  }
  .block-title-box .txt {
    font-size: 18px !important;
  }
  .block-title-box .txt br {
    display: none;
  }
}

.block-titleImg-box {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.block-titleImg-box .block-title {
  margin: 1rem 0rem 1rem 0.75rem;
  line-height: 1.3;
}
.block-titleImg-box .block-title p {
  margin-bottom: 5px;
}
.block-titleImg-box .block-title .txt-small {
  color: #888787;
}
.block-titleImg-box .block-title .txt-big {
  font-size: 24px;
  font-size: 1.5rem;
  color: #010101;
}

@media (min-width: 1025px) {
  .block-pl85 {
    padding-left: 85px;
  }
  .block-pl45 {
    padding-left: 45px;
  }
  .block-pl35 {
    padding-left: 35px;
  }
}
.bg-intro {
  background-color: #eeeae9;
}

.style__underLine {
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-bottom-color: #fff;
}
.style__underLine__small {
  border-top: none;
  border-bottom-width: 2px;
  border-bottom-style: dotted;
}

.tag-item {
  display: inline-block;
  width: auto;
  line-height: 1.1;
  margin: 2.5px;
  padding: 15px;
  text-align: center;
  color: white;
  background: #0d439b;
  line-height: 1.2;
}
.tag-item.main {
  border: #0097d8 1px solid;
  color: #0097d8 !important;
  font-size: 17px !important;
  padding: 8px 12px;
}

.form-control {
  border: none;
  height: 50px;
}
.form-control, .form-control:focus {
  background-color: #eeeeee;
}

.form-control,
.btn {
  border-radius: 0;
}

.modal {
  text-align: center;
  padding-right: 0 !important;
}
.modal .modal-content {
  border-color: #accccd;
  border-width: 3px;
}

.modal-open {
  padding-right: 0 !important;
}

@media (min-width: 500px) {
  .modal-dialog {
    width: 100%;
    max-width: 85rem;
  }
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .modal-dialog {
    max-width: 70rem;
  }
}
.modal-autoheight .modal-body {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100px !important;
  max-height: 100vh !important;
}
@media (min-width: 500px) {
  .modal-autoheight .modal-body {
    max-height: calc(100vh - 50px) !important;
  }
}
@media (max-width: 1080px) {
  .modal-autoheight .modal-body {
    height: calc(90vh - 1.5rem) !important;
  }
}
@media (max-width: 1080px) {
  .modal-autoheight {
    transform: translateY(5%);
  }
}

@media (min-width: 1025px) {
  .modal {
    text-align: center;
    padding: 0 !important;
  }
  .modal:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }
  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}
.checkbox_style > input[type=checkbox] {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}
.checkbox_style > input + label {
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  margin-top: 2px;
  margin-bottom: 5px;
  margin-right: 2px;
  margin-left: 0;
  padding-left: 1.75rem;
  cursor: pointer;
  border: none;
}
.checkbox_style > input + label::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  top: 2px;
  border: #000 1px solid;
}
.checkbox_style > input + label::after {
  content: "\f00c";
  font-family: FontAwesome;
  position: absolute;
  left: 2px;
  font-size: 1rem;
  opacity: 0;
}
.checkbox_style > input:checked + label::after {
  opacity: 1;
}
.checkbox_style p {
  margin-top: 10px;
}

.radio_style {
  margin-top: 10px !important;
}
.radio_style input[type=radio] {
  display: none;
}
.radio_style input + label {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 1;
  margin-bottom: 0;
}
.radio_style input + label::before, .radio_style input + label::after {
  content: "";
  display: inline-block;
  border-radius: 50%;
}
.radio_style input + label::before {
  width: 20px;
  height: 20px;
  border: #cecece 1px solid;
  background: #fff;
  vertical-align: middle;
  margin-right: 6px;
}
.radio_style input + label::after {
  width: 10px;
  height: 10px;
  background-color: transparent;
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translate(0%, -50%);
}
.radio_style input:checked + label::after {
  background-color: #2d456c;
}
@media (min-width: 1025px) {
  .radio_style ~ .radio_style {
    margin-left: 1rem;
  }
}

.btn-wrap {
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  margin: 2rem 0 1rem;
}
.btn-wrap > li {
  flex: 1;
}
.btn-wrap > li + li {
  margin-left: 1.5rem;
}
@media (max-width: 1080px) {
  .btn-wrap {
    margin-top: 1rem;
  }
  .btn-wrap > li + li {
    margin-left: 0.5rem;
  }
}

.btn {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  font-size: 17px;
  font-size: 1.0625rem;
}

.card-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.card-wrap .card {
  width: auto;
  height: auto;
  margin: 2px 8px 10px;
  border: none;
  background: transparent;
  box-shadow: none;
  border-radius: 0 !important;
}
.card-wrap .card__list {
  flex: 0 0 calc(50% - 16px);
  max-width: calc(50% - 16px);
}
.card-wrap .card p {
  position: relative;
  z-index: 3;
  margin: 0;
}
.card-wrap .card__body {
  padding: 0.5rem 0;
  line-height: 1.5;
}
.card-wrap .card-image-top {
  margin-bottom: 0.5rem;
}
.card-wrap .card__image {
  text-align: center;
  box-shadow: 0 0 40px 3px rgba(0, 0, 0, 0.15);
  margin-bottom: 0.75rem;
}
.card-wrap .card__image img {
  object-fit: cover;
  object-position: center;
}
.card-wrap .card__title {
  font-size: 20px;
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
}
.card-wrap.index-card-wrap .card__title {
  padding: 4px 8px 2px;
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: 700;
}
.card-wrap.index-card-wrap .card__description {
  padding: 8px 0;
  font-size: 17px;
  font-size: 1.0625rem;
  font-weight: 400;
  color: #332c2e;
}
@media (max-width: 810px) {
  .card-wrap {
    justify-content: flex-start;
    margin: 1rem 0;
  }
  .card-wrap .card {
    margin-left: 2px;
    margin-right: 2px;
    max-width: calc(50% - 4px);
    flex: 0 0 calc(50% - 4px);
  }
  .card-wrap .card__image img {
    height: 167px;
  }
}
@media (max-width: 640px) {
  .card-wrap .card__title {
    font-size: 3.90625vw;
  }
  .card-wrap .card__caption {
    font-size: 3.125vw;
  }
}

.price {
  color: #b0cbe2;
  font-size: 19px;
  font-size: 1.1875rem;
}

.card__note {
  text-align: center;
  color: #0d439b;
  background-color: #aed5d7;
  border-radius: 1.0526315789vw 0px;
  padding: 8px 12px 9px;
  line-height: 1.3;
  font-family: "Noto Sans TC";
}
@media (max-width: 1024px) {
  .card__note {
    border-radius: 4.6875vw 0px;
  }
}

.card__price {
  width: 100%;
}
.card__price p {
  margin-bottom: 2px !important;
}
.card__price .price {
  padding-left: 10px;
  padding-right: 2px;
}
@media (max-width: 640px) {
  .card__price p {
    margin-bottom: 5px !important;
  }
  .card__price span.item {
    display: block;
  }
  .card__price span.item + .price {
    padding-left: 0;
  }
}

.card__infoList {
  display: flex;
  align-items: flex-start;
  margin-top: 2.5rem;
  margin-bottom: 0.5rem;
}
.card__infoList .card__note {
  min-width: 60px;
  flex: none;
}
@media (min-width: 640px) {
  .card__infoList .card__note + .card__price {
    margin-left: 1rem;
  }
}
@media (max-width: 640px) {
  .card__infoList .card__note {
    min-width: 100%;
    margin-bottom: 10px;
    padding-bottom: 8px;
  }
  .card__infoList .card__note br {
    display: none;
  }
}
@media (min-width: 900px) {
  .card__infoList {
    margin-bottom: 2rem;
  }
}
@media (max-width: 640px) {
  .card__infoList {
    flex-wrap: wrap;
  }
}

.mainbanner .img img {
  width: 100%;
}
.mainbanner .txt {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 1450px;
  z-index: 1;
  color: #fff;
}
.mainbanner .txt-big {
  font-size: 36px;
  font-size: 2.25rem;
  margin-bottom: 10px;
}
.mainbanner .txt-small {
  font-size: 18px;
  font-size: 1.125rem;
}
@media (max-width: 1500px) {
  .mainbanner .txt {
    padding-left: 45px;
  }
}
@media (max-width: 810px) {
  .mainbanner .img img {
    height: 200px;
    object-fit: cover;
    object-position: center;
  }
  .mainbanner .txt {
    top: 50%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .mainbanner .txt-big {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

canvas {
  display: block;
  vertical-align: bottom;
}

#particles-js {
  position: absolute;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
}

.content-title {
  border-bottom: 1px solid #b8b8b8;
}
.content-title .item {
  font-size: 20px;
  color: #000;
}
.content-title .item a,
.content-title .item span {
  position: relative;
  display: block;
  padding: 32.5px 0 28px;
}
.content-title .item:hover, .content-title .item.current {
  color: #0097d8;
}
.content-title .item:hover a::after,
.content-title .item:hover span::after, .content-title .item.current a::after,
.content-title .item.current span::after {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background-color: #0097d8;
  position: absolute;
  bottom: 0;
}
.content-title .item ~ .item {
  margin-left: 30px;
}
.content-title .item ~ .item::before {
  content: "";
  display: block;
  width: 1px;
  height: 20px;
  background-color: #bfbfbf;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -6px;
}
.content-title .item ~ .item a,
.content-title .item ~ .item span {
  margin-left: 25px;
}
@media (max-width: 1500px) {
  .content-title .item {
    padding-left: 45px;
  }
  .content-title .item span {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media (max-width: 810px) {
  .content-title .item {
    padding-left: 30px;
  }
  .content-title .item a, .content-title .item span {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.image-slider {
  z-index: 2;
}
.image-slider .swiper-slide {
  position: relative;
  overflow: hidden;
}
@media (min-width: 1025px) {
  .image-slider .swiper-slide .index-banner-wrapper {
    margin: 7.8947368421vw auto 18.4210526316vw;
  }
}
@media (min-width: 1100px) and (max-width: 1300px) {
  .image-slider .swiper-slide .index-banner-wrapper {
    margin-bottom: 21.0526315789vw;
  }
}
@media (max-width: 1100px) {
  .image-slider .swiper-slide {
    min-height: 53.8947368421vw;
  }
}
.image-slider .swiper-slide .img-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-bottom: 40%;
}
@media (max-width: 640px) {
  .image-slider .swiper-slide .img-container {
    position: static;
    height: 200px;
  }
}
.image-slider .content {
  width: 100%;
  margin: auto;
  justify-content: flex-end;
  flex-direction: row;
}
@media (min-width: 1025px) {
  .image-slider .content {
    max-width: 73.6842105263vw;
  }
}
@media (max-width: 1080px) {
  .image-slider .content {
    max-width: 89.4736842105vw;
    margin-top: 3rem;
  }
}
@media (max-width: 640px) {
  .image-slider .content {
    max-width: 100%;
    flex-direction: column;
    background: #f0f4f3;
    padding: 1rem 1rem 5rem;
    margin-top: 0;
  }
}
.image-slider .swiper-pagination .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  opacity: 1;
  background-color: #fff;
  border: #192563 2px solid;
}
.image-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #192563;
}
@media (min-width: 1025px) {
  .image-slider .swiper-pagination {
    max-width: 20rem;
    bottom: calc(15 * (1vw + 1vh - 1vmin)) !important;
    left: auto !important;
    right: 14.4736842105vw;
    text-align: left;
  }
}
@media (max-width: 1480px) {
  .image-slider .swiper-pagination {
    right: 15.2631578947vw;
  }
}
@media (min-width: 1025px) and (max-width: 1100px) {
  .image-slider .swiper-pagination {
    right: 7.3684210526vw;
  }
}
@media (max-width: 1024px) {
  .image-slider .swiper-pagination {
    bottom: 15.625vw !important;
    max-width: 45%;
    margin-left: auto;
  }
}
@media (max-width: 810px) {
  .image-slider .swiper-pagination {
    bottom: 4.6875vw !important;
    max-width: 60%;
  }
}
@media (max-width: 640px) {
  .image-slider .swiper-pagination {
    bottom: 7.8125vw !important;
    max-width: 100%;
    margin: 0;
  }
}
.image-slider .text-wrapper {
  overflow: hidden;
  display: inline-flex;
}
.image-slider .text-wrapper .text-inner .sub-title,
.image-slider .text-wrapper .text-inner .title,
.image-slider .text-wrapper .text-inner .description {
  transform: translateY(50vw);
  transition: all ease 1s;
}
.image-slider .swiper-slide-active.active .text-inner .sub-title,
.image-slider .swiper-slide-active.active .text-inner .title,
.image-slider .swiper-slide-active.active .text-inner .description {
  transform: translateY(0);
  opacity: 1;
}
.image-slider .swiper-slide.active .text-inner .sub-title {
  transition-delay: 0.05s;
}
.image-slider .swiper-slide.active .text-inner .title {
  transition-delay: 0.1s;
}
.image-slider .swiper-slide.active .text-inner .description {
  transition-delay: 0.15s;
}

.index-content {
  padding: 120px 2rem 60px;
}
@media (max-width: 640px) {
  .index-content {
    padding-top: 50px;
  }
}
@media (max-width: 640px) {
  .index-banner img {
    height: 200px;
    object-fit: cover;
    object-position: center;
  }
}
.index-banner-title .en,
.index-banner-title .en b {
  color: #fff;
}
@media (max-width: 1024px) {
  .index-banner-title .en,
.index-banner-title .en b {
    color: #000;
  }
}
@media (max-width: 640px) {
  .index-banner-title * {
    color: #374a6f !important;
    word-break: break-word;
  }
  .index-banner-title .sub-title {
    font-size: 6.25vw;
  }
}
.index-intro-bg {
  padding-top: 0rem;
  margin-top: 1rem;
  z-index: 1;
}
.index-intro-bg::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  min-height: 48vw;
  position: absolute;
  top: -12.6315789474vw;
  left: 0;
  z-index: 0;
  background-image: url("../images/index-intro-bg.png");
  pointer-events: none;
}
@media (min-width: 1300px) and (max-width: 1500px) {
  .index-intro-bg::before {
    top: -18.9473684211vw;
    min-height: 57.8947368421vw;
  }
}
@media (max-width: 1500px) {
  .index-intro-bg::before {
    min-height: 65.7894736842vw;
    top: -23.6842105263vw;
  }
}
@media (max-width: 1480px) {
  .index-intro-bg::before {
    background-position: right;
    top: -18.4210526316vw;
  }
}
@media (max-width: 1024px) {
  .index-intro-bg::before {
    top: -20.5263157895vw;
    min-height: 89.4736842105vw;
  }
}
@media (max-width: 640px) {
  .index-intro-bg::before {
    height: auto;
    top: -28.90625vw;
    background-image: url("../images/index-intro-bg-mobile.png");
    background-position: top;
    padding-bottom: 218.75vw;
  }
}
@media (min-width: 1025px) {
  .index-intro-bg {
    margin-top: 4rem;
  }
}
@media (min-width: 1300px) and (max-width: 1500px) {
  .index-intro-bg {
    padding-top: 4em;
  }
}
@media (max-width: 640px) {
  .index-intro-bg {
    padding-top: 2.5rem;
    margin-top: 0;
  }
}
.index-pro-bg {
  z-index: 10;
}
.index-pro-bg::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  background-image: url("../images/index-proIntro-bg01.jpg");
  width: 100%;
  height: 0;
  padding-bottom: 54%;
  position: absolute;
  top: 9rem;
  left: 0;
  z-index: 0;
}
.index-pro-bg .img-circle {
  display: inline-block;
  width: 11.0526315789vw;
  height: 11.0526315789vw;
  position: absolute;
  right: 3.6842105263vw;
  top: -3.6842105263vw;
  bottom: 1px;
  z-index: 4;
  animation: moveCircle 1.5s linear infinite both;
}
@media (max-width: 810px) {
  .index-pro-bg .img-circle {
    width: 32.8125vw;
    height: 32.8125vw;
    top: -15.625vw;
  }
}
@media (min-width: 1025px) {
  .index-pro-bg {
    padding-right: 0;
    padding-bottom: 20px;
  }
  .index-pro-bg .img-name {
    margin-top: -7.3684210526vw;
  }
}
@media (min-width: 1100px) and (max-width: 1600px) {
  .index-pro-bg .img-name {
    margin-top: -3.1578947368vw;
  }
}
@media (min-width: 1025px) and (max-width: 1390px) {
  .index-pro-bg .img-name {
    margin-top: -8.4210526316vw;
  }
}
@media (min-width: 1025px) and (max-width: 1100px) {
  .index-pro-bg {
    padding-top: 0;
  }
}
@media (max-width: 1024px) {
  .index-pro-bg {
    padding-left: 0;
    padding-right: 0;
  }
  .index-pro-bg .img-name {
    margin-top: 2rem;
    text-align: center;
  }
  .index-pro-bg .block-title-box {
    margin-left: 1rem;
  }
}
@media (max-width: 640px) {
  .index-pro-bg::before {
    display: none;
  }
}
.index-pro-content {
  display: flex;
  margin-top: 2rem;
}
.index-pro-content .pro-introImg {
  flex: 1;
}
.index-pro-content .pro-introImg,
.index-pro-content .pro-introImg img {
  position: relative;
}
.index-pro-content .pro-info {
  display: flex;
  flex-grow: 2;
  margin-top: 2rem;
}
.index-pro-content .pro-list .title {
  font-size: 30px;
  font-size: 1.875rem;
  margin-bottom: 15px;
}
.index-pro-content .pro-list .title::before {
  content: "";
  display: block;
  width: 44px;
  height: 44px;
  background-image: url("../images/icon_doubleCircle.png");
  position: absolute;
  left: 0;
  top: 2.5px;
}
@media (min-width: 760px) and (max-width: 1100px) {
  .index-pro-content .pro-list .title {
    font-size: 27px;
    font-size: 1.6875rem;
  }
}
.index-pro-content .pro-list .list {
  font-size: 24px;
  font-size: 1.5rem;
  color: #3f3f3f;
  line-height: 1.5;
}
.index-pro-content .pro-list .list p {
  margin-bottom: 5px;
}
@media (min-width: 1101px) and (max-width: 1380px) {
  .index-pro-content .pro-list .list {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
@media (min-width: 760px) and (max-width: 1100px) {
  .index-pro-content .pro-list .list {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.index-pro-content .pro-list .title,
.index-pro-content .pro-list .list {
  padding-left: 60px;
}
.index-pro-content .pro-img {
  text-align: center;
}
@media (min-width: 700px) and (max-width: 1100px) {
  .index-pro-content .pro-img {
    flex: 1;
  }
}
.index-pro-content .btn-wrap {
  margin-top: 2rem;
}
@media (min-width: 700px) and (max-width: 1100px) {
  .index-pro-content .btn-wrap {
    padding-left: 3.5rem;
  }
}
.index-pro-content.left {
  z-index: 4;
}
.index-pro-content.left .pro-introImg {
  z-index: 1;
}
.index-pro-content.left .pro-introImg::before {
  content: "";
  display: block;
}
.index-pro-content.left .pro-info {
  padding-right: 5px;
}
.index-pro-content.right {
  z-index: 2;
}
.index-pro-content.right::before {
  content: "";
  background-size: contain;
  background-image: url("../images/index-proIntro-bg02.png");
  width: 58.9473684211vw;
  height: 58.6315789474vw;
  position: absolute;
  top: 0;
  right: -25rem;
  z-index: -1;
  opacity: 0.68;
}
.index-pro-content.right .pro-introImg {
  order: 1;
}
.index-pro-content.right .pro-info {
  padding-left: 5px;
}
@media (min-width: 1025px) {
  .index-pro-content .pro-introImg {
    flex: none;
    width: 46.3157894737vw;
  }
  .index-pro-content.left .pro-introImg {
    left: -8rem;
  }
  .index-pro-content.left .pro-introImg::before {
    background-image: url("../images/index-proIntro-img01-bg.png");
    position: absolute;
    top: -1rem;
    left: 3rem;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    z-index: 0;
  }
  .index-pro-content.left .pro-info {
    margin-left: -3.1578947368vw;
  }
  .index-pro-content.right {
    padding-top: 8rem;
    margin-top: -42.1052631579vw;
    padding-bottom: 5.2631578947vw;
    align-items: flex-end;
    justify-content: flex-end;
    overflow: hidden;
  }
  .index-pro-content.right .pro-introImg {
    top: 5.2631578947vw;
    left: auto;
    right: -9.0526315789vw;
  }
  .index-pro-content.right .pro-img {
    order: -1;
    margin-right: 0rem;
    margin-bottom: -3.1578947368vw;
  }
  .index-pro-content.right .pro-info {
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: -7.8947368421vw;
    z-index: 5;
  }
}
@media (min-width: 1301px) and (max-width: 1600px) {
  .index-pro-content.right {
    margin-top: -31.5789473684vw;
    padding-bottom: 10.5263157895vw;
  }
  .index-pro-content.right::before {
    top: -5rem;
    right: -20rem;
    padding-bottom: 75%;
    height: 0;
  }
}
@media (max-width: 1400px) {
  .index-pro-content.right::before {
    top: 4rem;
    right: -19rem;
  }
}
@media (min-width: 1025px) and (max-width: 1390px) {
  .index-pro-content .pro-list {
    flex-grow: 1;
  }
  .index-pro-content.right {
    margin-top: -21.0526315789vw;
    padding-bottom: 0vw;
    align-items: flex-start;
  }
  .index-pro-content.right::before {
    top: 2rem;
    right: -16rem;
    padding-bottom: 55%;
  }
  .index-pro-content.right .pro-introImg {
    top: 0vw;
  }
  .index-pro-content.right .pro-img {
    flex-grow: 4;
  }
}
@media (min-width: 1025px) and (max-width: 1250px) {
  .index-pro-content.right {
    margin-top: -5.2631578947vw;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .index-pro-content .pro-introImg {
    max-width: 27rem;
  }
  .index-pro-content.right::before {
    top: 2rem;
    right: -16rem;
    height: 0;
    padding-bottom: 50%;
  }
}
@media (max-width: 1024px) {
  .index-pro-content {
    overflow: hidden;
  }
  .index-pro-content .pro-list {
    flex-grow: 1;
  }
  .index-pro-content.left .pro-introImg {
    left: -5rem;
  }
  .index-pro-content.right {
    padding-left: 2rem;
  }
  .index-pro-content.right::before {
    height: 0;
    padding-bottom: 75%;
    right: 0;
  }
  .index-pro-content.right .pro-introImg {
    right: -5rem;
  }
  .index-pro-content.right .pro-info {
    margin-right: -5rem;
  }
}
@media (min-width: 700px) and (max-width: 1100px) {
  .index-pro-content .pro-list {
    flex-grow: 0;
  }
}
@media (max-width: 640px) {
  .index-pro-content .pro-introImg {
    flex: none;
    width: 200px;
  }
  .index-pro-content .pro-img {
    order: -1;
  }
  .index-pro-content .pro-info {
    flex-direction: column;
  }
  .index-pro-content .pro-info .title {
    font-size: 25px;
    font-size: 1.5625rem;
  }
  .index-pro-content .pro-info .list {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .index-pro-content .btn-wrap {
    justify-content: center !important;
  }
  .index-pro-content.left {
    flex-direction: column;
  }
  .index-pro-content.left::before {
    background-size: cover;
    padding-bottom: 100%;
  }
  .index-pro-content.left .pro-introImg {
    left: -2rem;
  }
  .index-pro-content.left .pro-img {
    position: absolute;
    top: -50vw;
    left: 40%;
  }
  .index-pro-content.left .pro-info {
    padding-left: 10px;
  }
  .index-pro-content.right::before {
    display: none;
  }
  .index-pro-content.right .pro-introImg {
    position: absolute;
    right: -3rem !important;
    left: auto !important;
  }
  .index-pro-content.right .pro-info {
    margin-right: -4rem;
  }
  .index-pro-content.right .pro-img {
    width: 50%;
  }
  .index-pro-content.right .btn-wrap {
    justify-content: flex-start !important;
    padding-left: 60px;
  }
}
.index-video-bg {
  background-position: top;
  background-image: url("../images/index-video-bg.png");
}
.index-video-list {
  margin-top: 1rem;
}

@media (min-width: 1025px) {
  .intro-content {
    align-items: flex-end;
    margin-top: -4rem;
  }
}
@media (max-width: 1080px) {
  .intro-content {
    flex-direction: column;
  }
}
.intro-txtWrap {
  flex: 1;
}
.intro-txtWrap .title {
  margin-top: 2rem;
  line-height: 1.5;
  font-size: 1.25rem;
}
.intro-txtWrap .txt {
  margin: 1.25rem 0 3rem;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 100;
}
.intro-txtWrap .btn {
  border-radius: 30px;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
  border-width: 2px;
}
@media (max-width: 1080px) {
  .intro-txtWrap .title {
    margin-top: 1rem;
  }
  .intro-txtWrap .txt {
    margin: 1rem 0;
  }
}
.intro-imgWrap {
  flex: 1.75;
  text-align: center;
}

.video-box {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.video-txt {
  justify-content: space-between;
  font-size: 20px;
  font-size: 1.25rem;
  color: #3d3e3e;
  margin-top: 1rem;
}

.banner-TopArrow-list {
  position: absolute;
  bottom: 5.2631578947vw;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  width: 105px;
}
.banner-TopArrow-list .banner-TopArrow {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  animation: jump 1.5s infinite;
}
@media (max-width: 1024px) {
  .banner-TopArrow-list {
    display: none;
  }
}

@keyframes jump {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 10px;
  }
  100% {
    bottom: 0;
  }
}
@keyframes moveCircle {
  0%, 100% {
    transform: translateY(-15%);
  }
  50% {
    transform: translateY(-5%);
  }
}
.aboutHistory-content {
  background-color: #f2f2f2;
}

.swiper--progress .swiper-button-prev,
.swiper--progress .swiper-button-next {
  display: block;
  width: 43px;
  height: 42px;
  z-index: 100;
  top: calc(50% + 2px);
}
.swiper--progress .swiper-button-prev::after,
.swiper--progress .swiper-button-next::after {
  display: none;
}
.swiper--progress .swiper-button-prev {
  left: 2%;
  background-image: url("../images/arrow_circle_left.png");
}
.swiper--progress .swiper-button-next {
  right: 2%;
  background-image: url("../images/arrow_circle_right.png");
}
@media (min-width: 1025px) {
  .swiper--progress {
    border-radius: 70px;
    background: #e1e0e0;
    overflow: hidden;
  }
}
@media (max-width: 1024px) {
  .swiper--progress .swiper-button-prev {
    left: 0;
  }
  .swiper--progress .swiper-button-next {
    right: 0;
  }
}

.swiper-container-wrapper--timeline .swiper-slide {
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}
.swiper-container-wrapper--timeline .swiper-slide .container {
  padding: 0;
  width: 100%;
}
.swiper-container-wrapper--timeline .swiper-slide .title {
  display: block;
  width: 100%;
  margin-top: 1rem;
  font-size: 18px;
  opacity: 0;
  transition: 0.5s ease 0.5s;
}
.swiper-container-wrapper--timeline .swiper-slide-active .title {
  opacity: 1;
}
.swiper-container-wrapper--timeline .swiper-pagination-progressbar {
  background-color: transparent;
  border-bottom: 1px solid #0097d8;
  width: calc(100% - 10rem) !important;
  height: 2px;
  position: absolute;
  left: 50% !important;
  top: calc(50% - 1px) !important;
  transform: translateX(-50%) !important;
  z-index: 1;
}
.swiper-container-wrapper--timeline .swiper-pagination-progressbar-fill {
  display: none;
}
@media (max-width: 1024px) {
  .swiper-container-wrapper--timeline .swiper-pagination-progressbar {
    width: calc(100% - 6rem) !important;
  }
}
.swiper-container-wrapper--timeline .swiper-pagination-custom {
  position: relative;
  list-style: none;
  margin: 2.75rem 0 1.25rem 7rem;
  padding: 0;
  display: flex;
  z-index: 11;
}
.swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch {
  display: block;
  position: relative;
}
.swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch::after {
  content: "";
  width: 40%;
  height: 1px;
  background: #0097d8;
  position: absolute;
  top: 50%;
  right: -20%;
  z-index: -1;
}
@media (min-width: 1355px) and (max-width: 1550px) {
  .swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch::after {
    right: -28%;
    width: 35%;
  }
}
.swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch .switch-title {
  display: inline-block;
  font-size: 18px;
  font-size: 1.125rem;
  font-family: Arial;
  font-weight: 500;
  background: #fff;
  border-radius: 20px;
  padding: 0.25rem 1.25rem;
  border: #3f3f3f 2px solid;
  text-align: center;
  transition: 0.2s all ease-in-out;
  transition-delay: 0s;
  cursor: pointer;
}
@media (min-width: 1025px) {
  .swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch .switch-title {
    margin-right: 3rem;
  }
}
@media (min-width: 1255px) and (max-width: 1550px) {
  .swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch .switch-title {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
@media (min-width: 1355px) and (max-width: 1550px) {
  .swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch .switch-title {
    margin-right: 1rem;
  }
}
@media (min-width: 1250px) and (max-width: 1350px) {
  .swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch .switch-title {
    margin-right: 2rem;
  }
}
.swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch.swiper-slide-thumb-active .switch-title {
  font-weight: 400;
  transition-delay: 0.4s;
  border-color: #0097d8;
  color: #0097d8;
}
.swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch.swiper-slide-thumb-active .switch-title:after {
  display: none;
  background: #000;
  width: 25px;
  height: 25px;
  transition-delay: 0.4s;
}
@media (min-width: 1025px) {
  .swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch {
    flex: none;
    width: auto !important;
    margin-left: 0.75rem;
    margin-right: 2.25rem;
  }
}
@media (min-width: 1301px) and (max-width: 1500px) {
  .swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch:last-of-type {
    margin-right: 1rem;
  }
  .swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch:last-of-type::after {
    display: none;
  }
  .swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch:last-of-type .switch-title {
    margin-right: 0;
  }
}
@media (min-width: 1025px) and (max-width: 1300px) {
  .swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch {
    margin-right: 0;
  }
  .swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch::after {
    width: 20%;
    right: -4%;
  }
}
@media (max-width: 1024px) {
  .swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch {
    text-align: center;
  }
  .swiper-container-wrapper--timeline .swiper-pagination-custom .swiper-pagination-switch::after {
    display: none;
  }
}
@media (min-width: 1025px) {
  .swiper-container-wrapper--timeline .swiper-pagination-custom .swiper {
    margin: 0;
  }
}
@media (max-width: 1024px) {
  .swiper-container-wrapper--timeline .swiper-pagination-custom {
    margin-left: 0;
    bottom: 0;
  }
}

.aboutInfo-content {
  display: flex;
  align-items: flex-start;
  padding-top: 1rem;
}
.aboutInfo-img {
  flex: none;
  width: 600px;
}
.aboutInfo-timeline {
  width: 100%;
  margin-left: 1rem;
}
.aboutInfo-img {
  text-align: center;
}
.aboutInfo-img .txt {
  color: #827f7f;
  font-family: arial;
  font-size: 24px;
  font-size: 1.5rem;
  padding-top: 7.5px;
  padding-left: 10px;
  text-align: left;
}
.aboutInfo-timeline {
  padding-top: 1rem;
  padding-left: 8rem;
}
@media (min-width: 800px) and (max-width: 1400px) {
  .aboutInfo-timeline {
    margin-left: 0;
  }
}
@media (max-width: 1024px) {
  .aboutInfo-img, .aboutInfo-timeline {
    width: 100%;
  }
  .aboutInfo-content {
    flex-direction: column;
    padding-top: 0;
  }
  .aboutInfo-timeline {
    padding-top: 0rem;
    padding-left: 5.25rem;
  }
}

/* Timeline */
.timeline {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  color: #333;
  position: relative;
  line-height: 1.4em;
  padding: 0px 20px 20px;
  list-style: none;
  text-align: left;
}
.timeline h1,
.timeline h2,
.timeline h3 {
  font-size: 1.4em;
}
.timeline .event {
  display: flex;
  position: relative;
  padding-left: 10px;
  padding-bottom: 45px;
}
.timeline .event:before {
  position: absolute;
  display: block;
  top: 0;
  left: -10.5rem;
  color: #030303;
  content: attr(data-date);
  text-align: right;
  font-size: 20px;
  min-width: 120px;
  font-family: arial;
}
.timeline .event:after {
  content: "";
  left: 5px;
  width: 1px;
  height: 100%;
  background: #192563;
  position: absolute;
  left: -11px;
  top: 10px;
}
.timeline .event .txt {
  position: relative;
  margin-bottom: 0;
  padding-left: 10px;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.5;
}
.timeline .event .txt::before {
  content: "";
  position: absolute;
  display: block;
  top: 8px;
  left: -25.5px;
  background: #192563;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}
.timeline .event:last-of-type {
  padding-bottom: 0;
}
.timeline .event:last-of-type:after {
  height: 0;
  top: 0px;
}

.pro-bg {
  background-image: url("../images/bg-pro.jpg");
  background-size: contain;
  background-position: right bottom;
}
.pro-txt, .pro-nameRadius {
  font-size: 1.5789473684vw;
  line-height: 1.2;
}
.pro-txt p, .pro-nameRadius p {
  margin-bottom: 0;
}
@media (max-width: 1080px) {
  .pro-txt, .pro-nameRadius {
    font-size: 3.90625vw;
  }
}
.pro-nameRadius {
  background-color: #dadada;
  border-radius: 70px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1.4473684211vw 2.8947368421vw;
}
.pro-nameRadius .img {
  display: flex;
  align-items: center;
  width: 2.3157894737vw;
  margin-right: 10px;
}
.pro-radius {
  display: block;
  height: 0;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 52.5%;
  border-radius: 25rem;
  border: #7b7b7b 1px dashed;
  position: absolute;
  top: -7.5%;
  z-index: 0;
}
.pro-introImg {
  position: relative;
  top: 1%;
  z-index: 1;
  text-align: center;
}
.pro-content .pro-nameRadius {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%);
}
@media (max-width: 1080px) {
  .pro-content .pro-nameRadius {
    position: relative;
    transform: translate(-50%, -25%);
    font-size: 2.5vw;
  }
}
@media (max-width: 640px) {
  .pro-content .pro-nameRadius {
    font-size: 3.75vw;
  }
}
.pro-content .pro-txt {
  top: -6.3157894737vw;
  right: auto;
  left: 0;
  text-align: right;
  padding-right: 11vw;
  z-index: 1;
}
@media (max-width: 1100px) {
  .pro-content .pro-txt {
    top: -9.4736842105vw;
  }
}
@media (max-width: 1080px) {
  .pro-content .pro-txt {
    top: 0vw;
    padding-right: 0;
    text-align: center;
    font-size: 2.5vw;
  }
}
@media (max-width: 640px) {
  .pro-content .pro-txt {
    font-size: 3.75vw;
  }
}
.pro-content-list.second .pro-nameRadius {
  transform: translate(-50%, -50%);
}
@media (max-width: 1080px) {
  .pro-content-list {
    margin-bottom: 1rem;
  }
}

.animate {
  animation-duration: 0.7s;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
}

/* Pop In */
.pop {
  animation-name: animate-pop;
}

@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
/* Slide Left */
.slide-left {
  animation-name: animate-slide-left;
}

@keyframes animate-slide-left {
  0% {
    opacity: 0;
    transform: translate(-100%, -75%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -75%);
  }
}
/* Slide Left */
.slide-left02 {
  animation-name: animate-slide-left02;
}

@keyframes animate-slide-left02 {
  0% {
    opacity: 0;
    transform: translate(-100%, -50%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}
/* Slide right */
.slide-right {
  animation-name: animate-slide-right;
}

@keyframes animate-slide-right {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.delay-1 {
  animation-delay: 1s;
}

.delay-2 {
  animation-delay: 2s;
}

.delay-3 {
  animation-delay: 3s;
}

.delay-4 {
  animation-delay: 4s;
}

.delay-5 {
  animation-delay: 5s;
}

.delay-6 {
  animation-delay: 6s;
}

.delay-7 {
  animation-delay: 7s;
}

.delay-10 {
  animation-delay: 10s;
}

.quality-bg {
  background-image: url("../images/bg-quality.jpg");
  padding-top: 120px;
  padding-bottom: 120px;
}

@media (min-width: 1025px) {
  .quality-list {
    max-width: 72.5%;
  }
}

.equip-bg {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: auto;
  background-image: url("../images/bg-equip.jpg");
  padding-top: 50px;
}

.equip-content-intro {
  display: flex;
  width: 100%;
  background-color: #fff;
  padding: 3rem 2rem 0.25rem;
}
@media (max-width: 1024px) {
  .equip-content-intro {
    flex-direction: column;
    padding: 1.5rem 1.25rem 0.25rem;
  }
}
.equip-introList {
  flex: 1;
  align-items: flex-start;
  padding: 0.5rem 0;
  color: #0c3766;
  font-size: 19px;
  font-size: 1.1875rem;
}
.equip-introList p {
  margin-bottom: 10px;
}
.equip-introList li {
  position: relative;
  border-bottom: 1px dashed #656565;
  margin-bottom: 1rem;
  width: calc(25% - 2rem);
  margin-right: 2rem;
  padding-left: 2rem;
}
.equip-introList li::before {
  content: "";
  display: block;
  width: 17px;
  height: 17px;
  background-image: url("../images/icon-list.png");
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -12px;
}
@media (min-width: 1025px) {
  .equip-introList {
    margin-left: 3rem;
  }
  .equip-introList li {
    margin-bottom: 45px;
  }
  .equip-introList li:nth-child(4n-4) {
    width: 25%;
    margin-right: 0;
    padding-right: 0;
  }
}
@media (max-width: 1500px) {
  .equip-introList li {
    width: calc(33% - 2rem) !important;
    margin-right: 2rem !important;
  }
}
@media (max-width: 1300px) {
  .equip-introList li {
    width: calc(50% - 0.5rem) !important;
    margin-right: 0.5rem !important;
  }
}
@media (max-width: 1024px) {
  .equip-introList {
    font-size: 17px;
    font-size: 1.0625rem;
  }
  .equip-introList li {
    width: calc(100% - 0.5rem) !important;
    padding-left: 1.5rem;
  }
}
@media (max-width: 640px) {
  .equip-introList li {
    width: calc(100% - 0.5rem);
  }
}
.equip-content-list {
  padding-right: 0;
}
.equip-content-list .block-titleImg-box {
  margin-top: 1rem;
  margin-bottom: 0rem;
}
.equip-instruments-list {
  justify-content: space-between;
  width: 100%;
}
.equip-instruments-list li {
  width: calc(50% - 1rem);
  margin-bottom: 0.5rem;
}
.equip-instruments-list li:last-child {
  margin-right: 0;
}

.equip-swiper {
  max-width: 75rem;
  margin: 1rem auto;
  overflow: hidden;
}
.equip-swiper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 500ms;
  transform: scale(0.7) !important;
}
.equip-swiper .swiper-slide::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
.equip-swiper .swiper-slide-active, .equip-swiper .swiper-slide-duplicate-active {
  transform: scale(1.15) !important;
}
.equip-swiper .swiper-slide-active::after, .equip-swiper .swiper-slide-duplicate-active::after {
  opacity: 0;
}
@media (max-width: 640px) {
  .equip-swiper .swiper-slide, .equip-swiper .swiper-slide-active, .equip-swiper .swiper-slide-duplicate-active {
    transform: scale(1) !important;
  }
}
.equip-swiper .swiper-button-next, .equip-swiper .swiper-button-prev {
  color: #aeaeae;
}
.equip-swiper .swiper-button-next::after, .equip-swiper .swiper-button-prev::after {
  font-size: 2rem;
}
.equip-swiper .swiper-button-prev {
  left: 0;
}
.equip-swiper .swiper-button-next {
  right: 0;
}
@media (min-width: 1025px) {
  .equip-swiper-container {
    padding: 2rem;
  }
}

.contact-bg {
  background-image: url("../images/bg-contact.jpg");
  padding-top: 120px;
  padding-bottom: 120px;
}

.contact-block {
  position: relative;
}
.contact-info .txt {
  color: #374a6f;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2.25;
  font-weight: 600;
  width: 100%;
  border: rgba(0, 0, 0, 0) 6px solid;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.contact-info .txt p {
  margin-bottom: 0;
}
@media (min-width: 1025px) {
  .contact-info .txt {
    width: 460px;
  }
}
@media (max-width: 1024px) {
  .contact-info .txt {
    font-size: 18px;
    font-size: 1.125rem;
    margin-top: 1rem;
  }
}
.contact-form {
  background: #fff;
  padding: 1rem;
  border: #000 1px solid;
  box-shadow: 0 0 10px 7px rgba(0, 0, 0, 0.15);
}
.contact-form legend {
  line-height: 1.5;
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 1rem;
}
.contact-form label {
  margin-bottom: 0;
}
.contact-form .form-control, .contact-form .form-control:hover, .contact-form .form-control:focus {
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #000000;
  outline: none;
  box-shadow: none;
  background-color: #fff;
}
.contact-form .btn-wrap li {
  display: flex;
  justify-content: center;
  width: 100%;
}
@media (max-width: 640px) {
  .contact-form .btn-wrap {
    flex-direction: column;
  }
  .contact-form .btn-wrap li {
    margin-bottom: 10px;
  }
}
@media (min-width: 1025px) {
  .contact-form {
    padding: 2rem;
    max-width: 62.5%;
  }
  .contact-form legend {
    margin-bottom: 2.75rem;
  }
}
@media (min-width: 1400px) {
  .contact-form {
    padding: 85px 130px;
  }
}
@media (max-width: 1024px) {
  .contact-form {
    margin-top: 2rem;
    max-width: 100%;
  }
}
@media (max-width: 1024px) {
  .contact {
    display: block;
  }
}

.hexagon {
  position: relative;
  margin: 100px auto;
  width: 190px;
  height: 110px;
  background-position: center center;
  z-index: 1;
  line-height: 110px;
  text-align: center;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 6px;
  color: #fff;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: color 500ms ease, text-shadow 500ms ease;
}
.hexagon :hover {
  color: #fff;
  text-shadow: 0 0 10px white;
}
.hexagon .face1,
.hexagon .face2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: inherit;
  z-index: -1;
  backface-visibility: hidden;
}
.hexagon .face1::before,
.hexagon .face2::before {
  content: "";
  position: absolute;
  width: 220px;
  height: 220px;
  background: inherit;
}
.hexagon .face1 {
  transform: rotate(60deg);
}
.hexagon .face1::before {
  left: 0;
  transform-origin: left top;
  transform: rotate(-60deg) translate(-110px, 0);
}
.hexagon .face2 {
  transform: rotate(-60deg);
}
.hexagon .face2::before {
  right: 0;
  transform-origin: right top;
  transform: rotate(60deg) translate(110px, 0);
}

.hex-grid__item {
  float: left;
  width: 200px;
  -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: translateZ(0);
}

.hex-grid__media {
  display: block;
  width: 100%;
}

.hexagon-style {
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 200px;
}
.hexagon-style img {
  display: block;
  width: 100%;
  position: absolute;
  z-index: 0;
  top: 50%;
  transform: translateY(-50%);
}
.hexagon-style:before, .hexagon-style:after {
  content: "";
  position: absolute;
  box-sizing: border-box;
  top: 0;
  width: 100%;
  height: 0;
  z-index: 5;
  border-left: 100px solid white;
  border-right: 100px solid white;
  border-bottom: 50px solid transparent;
}
.hexagon-style:after {
  transform: rotate(-180deg);
  top: auto;
  bottom: 0;
}

.hexagons-list {
  position: relative;
}
.hexagons-list .hexagons-txt {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-size: 1.125rem;
  margin-top: 0.5rem;
  z-index: 2;
}
.hexagons-list .hexagons {
  display: flex;
  box-shadow: 0px 25px 30px -30px rgba(0, 0, 0, 0.5);
  transform: scale(1);
  overflow: hidden;
}
.hexagons-list .hexagons .img {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 100%;
  height: 0;
  padding-bottom: 132%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: teal;
  cursor: pointer;
}
.hexagons-list .hexagons .img, .hexagons-list .hexagons .img::after {
  transition: all 0.3s ease-out;
}
.hexagons-list .hexagons .img::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.35;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 0;
}
@media (min-width: 1025px) {
  .hexagons-list:hover .hexagons .img {
    transform: scale(1.05);
  }
  .hexagons-list:hover .hexagons .img::after {
    opacity: 0;
  }
}
@media (max-width: 810px) {
  .hexagons-list .hexagons {
    margin-bottom: -0.5rem;
  }
  .hexagons-list .hexagons-txt {
    font-size: 17px;
    font-size: 1.0625rem;
  }
}