.aboutHistory-content{
    background-color:#f2f2f2
}

// 
$primary-color: #000;
$secondary-color: #888;

.swiper-content {
    // overflow: hidden;
}

.swiper--progress{
    
    .swiper-button-prev,
    .swiper-button-next {
      display: block;
      width:43px;
      height:42px;
      z-index:100;
      @extend %setBack;
      top: calc(50% + 2px);
      &::after{
          display: none;
      }
    }

    .swiper-button-prev{
      left: 2%;
      background-image:url('../images/arrow_circle_left.png')
    }

    .swiper-button-next{
      right: 2%;
      background-image:url('../images/arrow_circle_right.png')
    }

    @include setMinRwd{
      border-radius: 70px;
      background: #e1e0e0;
      overflow: hidden;
    }

    @include pad1024{
      .swiper-button-prev{
        left:0
      }

      .swiper-button-next{
        right:0
      }
    }
}

.swiper-container-wrapper {

    &--timeline {
      .swiper-slide {
        width:100%;
        display: flex;
        justify-content: center;
        border-radius: 10px;

        .container {
          padding: 0;
          width: 100%;
        }

        .title{
          display: block;
          width:100%;
          margin-top:1rem;
          font-size: 18px;
          opacity: 0;
          transition: 0.5s ease 0.5s;
        }

        &-active{
          .title{
            opacity: 1;
          }
        }
      }

      .swiper-pagination-progressbar {
        background-color: transparent;
        border-bottom: 1px solid $colorSubs;
        width: calc(100% - 10rem) !important;
        height: 2px;
        
        position: absolute;
        left: 50% !important;
        top: calc(50% - 1px) !important;
        transform: translateX(-50%) !important;
        z-index:1;
        &-fill {
            display: none;
        }
        @include pad1024{
          width: calc(100% - 6rem) !important;
        }
      }

      .swiper-pagination-custom {
        position: relative;
        list-style: none;
        margin: 2.75rem 0 1.25rem 7rem;
        padding: 0;
        display: flex;
        z-index: 11;
        
        .swiper-pagination-switch {
          display: block;
          position: relative;
          
          &::after{
            content: '';
            width: 40%;
            height: 1px;
            background: #0097d8;
            position: absolute;
            top: 50%;
            right: -20%;
            z-index:-1;
            @include setWidthRwd(1355px,1550px){
              right: -28%;
              width: 35%;
            }
          }

          .switch-title {
            display: inline-block;
            @include fontSize(18px);
            font-family: Arial;
            font-weight: 500;
            background:#fff;
            border-radius: 20px;
            padding: 0.25rem 1.25rem;
            border: #3f3f3f 2px solid;
            text-align:center;
            transition: 0.2s all ease-in-out;
            transition-delay: 0s;
            cursor: pointer;
            @include setMinRwd{
              margin-right: 3rem;
            }
            @include setWidthRwd(1255px,1550px){
              padding-left: 0.75rem;
              padding-right: 0.75rem;
            }
            @include setWidthRwd(1355px,1550px){
              margin-right: 1rem;
            }
            @include setWidthRwd(1250px,1350px){
              margin-right: 2rem;
            }
          }

          &.swiper-slide-thumb-active {
            .switch-title {
              font-weight: 400;
              transition-delay: 0.4s;
              border-color:$colorSubs;
              color:$colorSubs;
              &:after {
                display: none;
                background: $primary-color;
                width: 25px;
                height: 25px;
                transition-delay: 0.4s;
              }
            }
          }

          @include setMinRwd{
            flex: none;
            width: auto !important;
            margin-left:0.75rem;
            margin-right: 2.25rem;
          }

          @include setWidthRwd(1301px,1500px){
            &:last-of-type{
              margin-right: 1rem;
              &::after{
                display: none;
              }
              .switch-title{
                margin-right: 0;
              }
            } 
          }

          @include setWidthRwd(1025px,1300px){
            margin-right:0;
            &::after{
              width: 20%;
              right: -4%;
            }
          }

          @include pad1024{
            text-align:center;
            &::after{
              display:none
            }
          }
        }

        @include setMinRwd{
          .swiper{
            margin:0;
          }
        }
        
        @include pad1024{
          margin-left: 0;
          bottom:0;
        }
      }
    }
}

.aboutInfo{
    &-content{
        display: flex;
        align-items:flex-start;
        // padding-top:4rem
        padding-top: 1rem;
    }
    
    &-img{
      flex:none;
      width: 600px;
    }

    &-timeline{
        // width:calc(100% - 600px);
        width: 100%;
        margin-left: 1rem;
    }

    &-img{
      text-align:center;
        .txt{
            color:#827f7f;
            font-family: arial;
            @include fontSize(24px);
            padding-top:7.5px;
            padding-left:10px;
            text-align: left;
        }
    }
    
    &-timeline{
        padding-top: 1rem;
        padding-left: 8rem
    }

    @include setWidthRwd(800px,1400px){
        &-timeline{
            margin-left: 0;
        }
    }

    @include pad1024{
        &-img,
        &-timeline{
            width:100%
        }

        &-content{
            flex-direction:column;
            padding-top:0
        }
        
        &-timeline{
            padding-top: 0rem;
            padding-left:5.25rem
        }
    }
}

/* Timeline */
.timeline {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  color: #333;
  position: relative;
  line-height: 1.4em;
  padding: 0px 20px 20px;
  list-style: none;
  text-align: left;

  h1,
  h2,
  h3 {
      font-size: 1.4em;
  }
  
  .event {
    display:flex;
    position: relative;
    padding-left: 10px;
    padding-bottom: 45px;

    &:before {
        position: absolute;
        display: block;
        top: 0;
        left: -10.5rem;
        color: #030303;
        content: attr(data-date);
        text-align: right;
        font-size: 20px;
        min-width: 120px;
        font-family: arial;
    }

    &:after {
        content: "";
        left: 5px;
        width: 1px;
        height: 100%;
        background: #192563;
        position: absolute;
        left: -11px;
        top: 10px;
    }

    .txt{
        position:relative;
        margin-bottom: 0;
        padding-left: 10px;
        @include fontSize(20px);
        line-height: 1.5;
        &::before{
            content:'';
            position: absolute;
            display: block;
            top: 8px;
            left: -25.5px;
            background: #192563;
            border-radius: 50%;
            height: 10px;
            width: 10px;
        }
    }

    &:last-of-type {
        padding-bottom: 0;
        &:after {
            height:0;
            top: 0px;
        }
    }
  } 
}