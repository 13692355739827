.nav-header{
  position: sticky;
  top: 0;
  z-index: 10000;
  background: #fff;
}

.nav-fostrap {
  @extend %setFlex-center;
  justify-content: space-between;
  background: $colorBack;

  ul {
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: block;
    z-index:2;
    @include setMinRwd{
      text-align: right;
    }
  }

  li {
    list-style-type: none;
    margin: 0;
    display: inline-block;
    position: relative;

    a {
      position: relative;
      z-index: 1;
      @include fontSize(20.5px);
      color: $colorBlack;
      display: inline-block;
      outline: 0;
      &::after{
        content: '';
        display: block;
        @include setSize(30px,30px);
        background-color: $colorItem;
        position: absolute;
        top: 0;
        left: -0.5rem;
        border-radius: 50%;
        transition:0.25s;
        opacity: 0;
        z-index: -1;
      }
    }

    ul.dropdown {
      display: flex;
      justify-content: center;
      width: 100%;
      min-width: 160px;
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -80px;
      padding-top: 0;
      opacity: 0;
      visibility: hidden;
      z-index: 100;
      li {
        padding-top:0.75rem ;
        padding-bottom:1.5rem ;
        a {
          padding: 0 1rem;
          font-weight: 400;
          color: #000;
          line-height: 1;
          @include fontSize(20px);
          &::after{
            display: none;
          }
          &:hover{
            color: $colorMain;
          }
        }
        &~li{
          a{
            border-left: 1px solid #000;
          }
        }
      }
    }
    
    &:hover ul.dropdown {
      opacity: 1;
      visibility: visible;
    }
    
    &:hover,
    &.active{
      a {
        color: $colorMain;
        &::after{
          opacity: 1;
        }
      }
    }
  }

  &-link{
    display: flex;
    align-items: center;
    flex: 1;
    @include pad1024{
      flex-direction: column;
      padding-left: 10px;
      padding-right: 10px;
      ul,
      .dropdown-lang{
        width: 100%;
      }
      .dropdown-lang{
        margin-top: 15px;
        .dropdown-toggle{
          display: none;
        }
        .dropdown-menu{
          display: flex;
          width: 100%;
          padding: 0;
        }
        .dropdown-item{
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          text-align: center;
          border-right: 1px solid rgba(0,0,0,.15);
          &:last-child{
            border-right: none
          }
        }
      }
    }
  }

  @include setMinRwd{
    padding-top: 0.5rem;
    li {
      padding:15px 2% 15px 3.5%;
      &:first-child{
        padding-left: 0;
      }
    }
  }

  @include setWidthRwd(1005px,1600px){
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @include setWidthRwd(1005px,1200px){
    li{
      a{
        font-size: 16px;
      }
    }
  }

}

  .nav-fostrap-menuBg{
    position: absolute;
    left: 0;
    top: 5rem;
    @include setSize(100%,65px);
    background: rgba(#d4e8ce,.37);
    z-index: 1;
    display: none;
    &.active{
      display: block;
    }
  }

  .arrow-down {
    position: absolute;
    right: 1rem;
  }

  .title-mobile {
    display: none;
  }

@include setStyleMinRwd(1025px) {
  .container {
    width: 96%;
  }
  
  .nav-bg-fostrap,
  .arrow-down {
    display: none;
  }

}

@include setWidthRwd(1025px, 1200px){
  .nav-logo{
    display: block;
    width: 30vw;
  }
}

@include pad1024{
  .nav-fostrap {
    background: #fff;
    @include setSize(55vw,100%);
    display: block;
    position: fixed;
    left: -100%;
    top: 0px;
    transition: left 0.25s ease;
    z-index:12;
    li{
      a{
        &::after{
          display: none !important;
        }
      }
    }
  }

  .title-mobile {
    display: block;
    max-width: 70vw;
  }

  .nav-logo{
    display: block;
    margin: auto;
  }

  .mobile-closeBtn{
    @include setSize(45px,45px);
    background-color: #000;
    text-align: center;
    color:$colorBack;
    position: absolute;
    right: -45px;
    top: -74px;
    z-index: 6;
    @include fontSize(25px);
    a{
      display: flex;
      align-items: center;
      justify-content: center;
      @include setSize(100%,100%);
    }
  }

  .nav-fostrap{
      &.visible {
          left: 0px;
          transition: left 0.25s ease;
      }
  }

  .nav-bg-fostrap {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    min-height: 65px;
    margin: 0;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    z-index:5;
  }

  .navbar-fostrapClick {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    padding: 12px;

    .icon{
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    span {
      height: 2px;
      background: $colorMain;
      margin: 2px 5px;
      display: block;
      width: 20px;

      &:nth-child(2),
      &:nth-child(3) {
        width: 20px;
      }
    }

    .txt{
      @include fontSize(14px)
    }
  }

  .nav-fostrap {
    ul {
      padding-top: 5px;
    }

    li {
      display: block;
      border-bottom: #eee 1px solid;
      a {
        display: block;
        @include fontSize(16px);
        padding: 6px 12px;
      }

      &:first-child:hover a {
        border-radius: 0;
      }

      ul.dropdown {
        position: static;
        display: none !important;
        margin-left: 0;
        &.active{
          display: block !important;
          opacity: 1;
          visibility: visible;
        }

        li {
          padding: 0;
          border-bottom-color:$colorMain;
          a {
            background: darken($colorMain, 10%);
            border-bottom: none;
            color: #fff !important;
          }
        }
      }

      &:hover,
      &.active {
        a {
          background: $colorMain;
          color: #fff !important;
        }
      }

      a{
        &.active{
          background: darken($colorMain, 5%);
          color: #fff !important;
          .arrow-down{
            transform: rotate(180deg);
          }
        }
      }

      ul.dropdown {
        li {
          a {
            padding: 15px 10px 15px 30px;
          }
        }
      }
    }
  }
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.nav-overlay {
  @include setSize(100%,100%);
  background: rgba(0,0,0,0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: none;
  opacity: 0;
  &.show{
      display: block;
      opacity: 1;
  }
}

// 版本
.dropdown-lang{
  flex:none;
  .dropdown-toggle{
    display: flex;
    align-items: center;
    border-radius: 20px;
    border:$colorMain 1px solid;
    padding: 0.2em 0.75em 0.25em;
    color:$colorMain;
    line-height: 27px;
    @include fontSize(16px);
    &::after{
      display: none;
    }
    .icon{
      flex:none;
      margin-right: 0.45rem;
      margin-top: -2px;
    }
  }

  .dropdown-menu{
    min-width: 5.75rem;
    border-color:$colorMain;
    padding:0;
    margin-top: 0.25rem;
    border-radius: 1rem;
    overflow: hidden;
    .dropdown-item {
      text-align: center;
      padding-top: 0.35rem;
      padding-bottom: 0.35rem;
      @include setMinRwd{
        &:hover{
          background-color: $colorMain;
          color:#fff;
        }
        &~.dropdown-item{
          border-top: 1px solid $colorMain;
        }
      }
    }
  }

  @include setMinRwd{
    margin-left: 10px;
  }
}