.card-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .card {
    width: auto;
    height: auto;
    margin: 2px 8px 10px;
    border:none;
    background:transparent;
    box-shadow:none;
    border-radius: 0 !important;
    &__list{
      flex: 0 0 calc((100% / 2) - 16px);
      max-width: calc((100% / 2) - 16px);
    }
    p{
      position: relative;
      z-index: 3;
      margin: 0;
    }

    &__body {
      padding: .5rem 0;
      line-height: 1.5;
    }

    &-image-top{
      margin-bottom: 0.5rem;
    }

    &__image {
      text-align: center;
      box-shadow: 0 0 40px 3px rgba(0, 0, 0, 0.15);
      margin-bottom: 0.75rem;
      img {
        object-fit: cover;
        object-position: center;
      }
    }
    &__title {
      @include fontSize(20px);
      margin-bottom: 0.75rem;
    }
  }

  &.index-card-wrap{
    .card {
      &__title {
        padding: 4px 8px 2px;
        @include fontSize(22px);
        font-weight: 700;
        p{
          @extend .ellipsis;
        }
      }

      &__description {
        padding: 8px 0;
        @include fontSize(17px);
        font-weight:400;
        color:#332c2e;
      }

    }
  }

  @include pad{
    justify-content: flex-start;
    margin:1rem 0;
    .card{
      margin-left: 2px;
      margin-right: 2px;
      max-width: calc((100% / 2) - 4px);
      flex:0 0 calc((100% / 2) - 4px);

      &__image {
        img {
          height: 167px;
        }
      }
    }
  }
  @include mobile{
    .card{
      &__title {
        font-size: get-vw-mobile(25px);
      }
      &__caption {
        font-size: get-vw-mobile(20px);
      }
    }
  }
}

// 另類樣式
.price{
  color:$colorItem;
  @include fontSize(19px)
}

.card__note{
  text-align: center;
  color:$colorMain;
  background-color: #aed5d7;
  border-radius: get-vw(20px) 0px;
  padding: 8px 12px 9px;
  line-height: 1.3;
  font-family: $fontNotosanc-tc;
  @include pad1024{
    border-radius: get-vw-mobile(30px) 0px;
  }
}

.card__price{
  width: 100%;
  p{
    margin-bottom: 2px !important;
  }

  .price{
    padding-left: 10px;
    padding-right: 2px;
  }

  @include mobile{
    p{
      margin-bottom: 5px !important;
    }
    span.item{
      display: block;
      &+.price{
        padding-left: 0;
      }
    }
  }

}

.card__infoList{
  display: flex;
  align-items:flex-start;
  margin-top: 2.5rem;
  margin-bottom:0.5rem;
  .card__note{
    min-width: 60px;
    flex:none;
    @include setStyleMinRwd(640px){
      &+.card__price{
        margin-left: 1rem;
      }
    }
    @include mobile{
      min-width: 100%;
      margin-bottom: 10px;
      padding-bottom: 8px;
      br{
        display: none;
      }
    }
  }
  
  @include setStyleMinRwd(900px){
    margin-bottom: 2rem;
  }

  @include mobile{
    flex-wrap: wrap;
  }
}
  