.pro{
    &-bg{
        @extend %setBack;
        background-image: url("../images/bg-pro.jpg");
        background-size: contain;
        background-position: right bottom;
    }

    // 標籤跟內容
    &-txt,
    &-nameRadius{
        font-size: get-vw(30px);
        line-height: 1.2;
        p{
            margin-bottom: 0;
        }
        @include pad1080{
            font-size: get-vw-mobile(25px);
        }
    }

    // 標籤
    &-nameRadius{
        background-color: #dadada;
        border-radius: 70px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: get-vw(27.5px) get-vw(55px);
        .img{
            display: flex;
            align-items: center;
            width: get-vw(44px);
            margin-right: 10px;
        }
    }

    // 有圓弧且虛線的圖片區塊
    &-radius{
        display: block;
        height: 0;
        padding-top: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 52.5%;
        border-radius: 25rem;
        border: #7b7b7b 1px dashed;
        position: absolute;
        top: -7.5%;
        z-index: 0;
    }

    // 圖片介紹
    &-introImg{
        position: relative;
        top: 1%;
        z-index: 1;
        text-align: center;
    }

    &-content{
        .pro-nameRadius{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-75%);
            @include pad1080{
                position: relative;
                transform: translate(-50%,-25%);
                font-size: get-vw-mobile(16px);
            }
            @include mobile{
                font-size: get-vw-mobile(24px);
            }
        }

        .pro-txt{
            top: get-vw(-120px);
            right: auto;
            left: 0;
            text-align: right;
            padding-right: 11vw;
            z-index: 1;
            @include setStyleRwd(1100px){
                top: get-vw(-180px);
            }
            @include pad1080{
                top: get-vw-mobile(0px);
                padding-right: 0;
                text-align: center;
                font-size: get-vw-mobile(16px);
            }
            @include mobile{
                font-size: get-vw-mobile(24px);
            }
        }

        &-list{
            &.second{
                .pro-nameRadius{
                    transform: translate(-50%,-50%);
                }
            }
            @include pad1080{
                margin-bottom: 1rem;
            }
        }
    }
}

.animate {
    animation-duration: 0.7s;
    animation-timing-function: cubic-bezier(.26, .53, .74, 1.48);
    animation-delay: .5s;
    animation-fill-mode: backwards;
}

/* Pop In */
.pop { animation-name: animate-pop; }

@keyframes animate-pop {
    0% {
      opacity: 0;
      transform: scale(0.5, 0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
}


/* Slide Left */
.slide-left { animation-name: animate-slide-left; }

@keyframes animate-slide-left {
  0% {
    opacity: 0;
    transform: translate(-100%, -75%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -75%);
  }
}

/* Slide Left */
.slide-left02 { animation-name: animate-slide-left02; }

@keyframes animate-slide-left02 {
  0% {
    opacity: 0;
    transform: translate(-100%, -50%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}


/* Slide right */
.slide-right { animation-name: animate-slide-right; }

@keyframes animate-slide-right {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.delay-1 {
    animation-delay: 1s;
}

.delay-2 {
    animation-delay: 2s;
}

.delay-3 {
    animation-delay: 3s;
}

.delay-4 {
    animation-delay: 4s;
}

.delay-5 {
    animation-delay: 5s;
}

.delay-6 {
    animation-delay: 6s;
}

.delay-7 {
    animation-delay: 7s;
}

.delay-10 {
    animation-delay: 10s;
}